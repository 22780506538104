export class FilterRequest {
  public orderStatus: string = '';
  public orderId: string = '';
  public tradeId: string = '';
  public fromDate: string = '';
  public toDate: string = '';
  public pageSize: Number = 0;
  public currentPage: Number = 0;
  public assetType: string = '';
}
