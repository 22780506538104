<section id="triton">
  <div class="container-fluid">
    @if (activeLink==='saltend') {
    <!--SALTEND Dashboard Listing -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-4">
            <h3>
              Last updated time ({{
              lastSyncDateTime | date : "dd/MM/yyyy, hh:mm a"
              }})
            </h3>
          </div>
          <div class="col-md-3">
            <form [formGroup]="columnForm">

              <nj-multi-select [isFloatingLabel]="true" formControlName="columns">
                <ng-container njSelectOptions>
                  <li nj-list-item [attr.data-category]="'unit'" [attr.data-value]="'U1'">U1</li>
                  <li nj-list-item [attr.data-category]="'unit'" [attr.data-value]="'U2'">U2</li>
                  <li nj-list-item [attr.data-category]="'unit'" [attr.data-value]="'U3'">U3</li>
                  <li nj-list-item [attr.data-category]="'unit'" [attr.data-value]="'U4'">U4</li>
                  <li nj-list-item [attr.data-category]="'unit'" [attr.data-value]="'REQUIRED'">REQUIRED</li>
                  <li nj-list-item [attr.data-category]="'position'" [attr.data-value]="'CONTRACTED'">CONTRACTED</li>
                  <li nj-list-item [attr.data-category]="'position'" [attr.data-value]="'TODO'">TODO</li>
                  <li nj-list-item [attr.data-category]="'position'" [attr.data-value]="'SUBMITTED'">SUBMITTED</li>
                  <li nj-list-item [attr.data-category]="'position'" [attr.data-value]="'PENDING'">PENDING</li>
                </ng-container>
                <ng-container njFormLabel>Hide SALTEND Columns</ng-container>
              </nj-multi-select>
            </form>
          </div>
          <div class="col-md-5 btn">
            <button type="button" (click)="onUpdate()" [disabled]="isUpdateLoading" class="nj-btn">
              Update ALL
              <span aria-live="polite" aria-atomic="true" [class.nj-spinner]="isUpdateLoading"
                [class.nj-spinner--inverse]="isUpdateLoading"
                [class.nj-spinner--xs]="isUpdateLoading"></span></button>&nbsp;&nbsp;
            <button type="button" (click)="openMELSELModel(modalToMELSELOpen)" class="nj-btn">
              MEL & SEL
            </button>
          </div>
        </div>
      </div>
      <div class="panel-body mainrow">
        <hr />
        <div class="row row-header1">
          <div class="col-md-12">
            <table class="nj-table nj-table--hover">
              <colgroup>
                <col id="hhTH" span="3" />
                <col id="saltTH" span="9" />
              </colgroup>
              <thead>
                <tr>
                  <th id="hhTHBg" colspan="3">HH local Date/Time</th>
                  @if (saltend_unitsColumnCount != 0) {
                  <th id="unitsTHBg" [attr.colspan]="saltend_unitsColumnCount">PNs (Units)</th>
                  }
                  @if (saltend_positionsColumnsCount != 0) {
                  <th id="saltTHBg" [attr.colspan]="saltend_positionsColumnsCount">Positions and orders</th>
                  }
                </tr>
                <tr>
                  <th>DATETIME</th>
                  <th>Block</th>
                  <th>PERIOD</th>
                  @if (visibleColumns.saltend.U1) {
                  <th>U1</th>
                  } @if (visibleColumns.saltend.U2) {
                  <th>U2</th>
                  } @if (visibleColumns.saltend.U3) {
                  <th>U3</th>
                  } @if (visibleColumns.saltend.U4) {
                  <th>U4</th>
                  } @if (visibleColumns.saltend.REQUIRED) {
                  <th>REQUIRED</th>
                  } @if (visibleColumns.saltend.CONTRACTED) {
                  <th>CONTRACTED</th>
                  } @if (visibleColumns.saltend.TODO) {
                  <th>TODO</th>
                  } @if (visibleColumns.saltend.SUBMITTED) {
                  <th>SUBMITTED</th>
                  } @if (visibleColumns.saltend.PENDING) {
                  <th>PENDING</th>
                  }
                </tr>
              </thead>

              <tbody>
                @for (item of dashboardList; track item) {
                <tr [class]="getRowClass(item)">
                  <td scope="row">{{ item.settlementDate }}</td>
                  <td>
                    <span title="Submit Block2H Order to MAID" class="todo"
                      (click)="openModal(modalToOpen, item, true)">
                      {{ item.block }} </span>
                  </td>
                  <td>{{ item.period }}</td>
                  @if (visibleColumns.saltend.U1){
                  <td class="list_input" scope="row">
                    <div class="nj-form-item__field-wrapper">
                      <input type="number" (keyup)="onKeyUp($event, item)" (keydown)="onKeydown($event, item)"
                        (click)="onCellClick($event,item)" data-field="saL_U1"
                        [ngStyle]="{'background-color': item.sal_u1_bgColor}" class="nj-form-item__field textBox"
                        [value]="item.saL_U1" />
                    </div>
                  </td>
                  } @if (visibleColumns.saltend.U2){
                  <td class="list_input">
                    <div class="nj-form-item__field-wrapper">
                      <input type="number" (keyup)="onKeyUp($event, item)" (keydown)="onKeydown($event, item)"
                        (click)="onCellClick($event,item)" data-field="saL_U2"
                        [ngStyle]="{'background-color': item.sal_u2_bgColor}" class="nj-form-item__field"
                        [value]="item.saL_U2" />
                    </div>
                  </td>
                  } @if (visibleColumns.saltend.U3){
                  <td class="list_input">
                    <div class="nj-form-item__field-wrapper">
                      <input type="number" (keyup)="onKeyUp($event, item)" (keydown)="onKeydown($event, item)"
                        (click)="onCellClick($event,item)" data-field="saL_U3"
                        [ngStyle]="{'background-color': item.sal_u3_bgColor}" class="nj-form-item__field"
                        [value]="item.saL_U3" />
                    </div>
                  </td>
                  } @if (visibleColumns.saltend.U4){
                  <td class="list_input">
                    <div class="nj-form-item__field-wrapper">
                      <input type="number" (keyup)="onKeyUp($event, item)" (keydown)="onKeydown($event, item)"
                        (click)="onCellClick($event,item)" data-field="saL_U4"
                        [ngStyle]="{'background-color': item.sal_u4_bgColor}" class="nj-form-item__field"
                        [value]="item.saL_U4" />
                    </div>
                  </td>
                  } @if (visibleColumns.saltend.REQUIRED){
                  <td>{{ calculateREQD(item) }}</td>
                  } @if (visibleColumns.saltend.CONTRACTED){
                  <td>{{ getSalCount(item) }}</td>
                  } @if (visibleColumns.saltend.TODO){
                  <td>
                    <span [class]="item.bgColor" title="Submit HalfHour Order to MAID"
                      (click)="openModal(modalToOpen, item, false)" class="todo">
                      {{ calculateTODO(item) }} </span>
                  </td>
                  } @if (visibleColumns.saltend.SUBMITTED){
                  <td>
                    <span [class]="item.sal_submitted_badge" (click)="openOrderModel(modalToOrderOpen, item)"
                      class="todo">{{ convertValue(item.saL_Submitted) }}</span>
                  </td>
                  } @if (visibleColumns.saltend.PENDING){
                  <td>{{ convertValue(item.pendingQty) }}</td>
                  }
                </tr>
                } @if (dashboardList?.length == 0) {
                <tr>
                  <td colspan="13" class="msg">No Records Found!</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    } @else if (activeLink==='indqueens') {
    <!--INDIAN QUEEN Dashboard Listing -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-4">
            <h3>
              Last updated time ({{
              lastSyncDateTime | date : "dd/MM/yyyy, hh:mm a"
              }})
            </h3>
          </div>
          <div class="col-md-3">
            <form [formGroup]="columnForm">
              <nj-multi-select [isFloatingLabel]="true" formControlName="columns">
                <ng-container njSelectOptions>
                  <li nj-list-item>U1</li>
                  <li nj-list-item>CONTRACTED</li>
                  <li nj-list-item>TODO</li>
                  <li nj-list-item>SUBMITTED</li>
                  <li nj-list-item>PENDING</li>
                </ng-container>
                <ng-container njFormLabel>Hide INDIAN QUEENS Columns</ng-container>
              </nj-multi-select>
            </form>
          </div>
          <div class="col-md-5 btn">
            <button type="button" (click)="onUpdate()" [disabled]="isUpdateLoading" class="nj-btn">
              Update ALL
              <span aria-live="polite" aria-atomic="true" [class.nj-spinner]="isUpdateLoading"
                [class.nj-spinner--inverse]="isUpdateLoading"
                [class.nj-spinner--xs]="isUpdateLoading"></span></button>&nbsp;&nbsp;
            <button type="button" (click)="openMELSELModel(modalToMELSELOpen)" class="nj-btn">
              MEL & SEL </button>
          </div>
        </div>
      </div>

      <div class="panel-body mainrow">
        <hr />
        <div class="row row-header1">
          <div class="col-md-12">
            <table class="nj-table nj-table--hover">
              <colgroup>
                <col id="hhTH" span="3" />
                <col id="indTH" span="8" />
              </colgroup>
              <thead>
                <tr>
                  <th id="hhTHBg" colspan="3">HH local Date/Time</th>
                  @if (indQueen_unitsColumnCount != 0) {
                  <th id="unitsTHBg" [attr.colspan]="indQueen_unitsColumnCount">PNs (Units)</th>
                  }
                  @if (indQueen_positionsColumnsCount != 0) {
                  <th id="saltTHBg" [attr.colspan]="indQueen_positionsColumnsCount">Positions and orders</th>
                  }
                </tr>
                <tr>
                  <th>DATETIME</th>
                  <th>Block</th>
                  <th>PERIOD</th>
                  @if (visibleColumns.india_Queen.U1) {
                  <th>U1</th>
                  } @if (visibleColumns.india_Queen.CONTRACTED) {
                  <th>CONTRACTED</th>
                  } @if (visibleColumns.india_Queen.TODO) {
                  <th>TODO</th>
                  } @if (visibleColumns.india_Queen.SUBMITTED) {
                  <th>SUBMITTED</th>
                  } @if (visibleColumns.india_Queen.PENDING) {
                  <th>PENDING</th>
                  }
                </tr>
              </thead>
              <tbody>
                @for (item of dashboardList; track item) {
                <tr [class]="getRowClass(item)">
                  <td scope="row">{{ item.settlementDate }}</td>
                  <td>
                    <span title="Submit Block2H Order to MAID" class="todo"
                      (click)="openModal(modalToOpen, item, true)">
                      {{ item.block }}
                    </span>
                  </td>
                  <td>{{ item.period }}</td>
                  @if (visibleColumns.india_Queen.U1){
                  <td class="list_input" scope="row">
                    <div class="nj-form-item__field-wrapper">
                      <input type="number" (keyup)="onIndQueenKeyUp($event, item)" (keydown)="onKeydown($event, item)"
                        (click)="onCellClick($event,item)" data-field="indQ_U1"
                        [ngStyle]="{'background-color': item.Ind_u1_bgColor}" class="nj-form-item__field"
                        [value]="item.indQ_U1" />
                    </div>
                  </td>
                  } @if (visibleColumns.india_Queen.CONTRACTED){
                  <td>{{ item.indQ_Cont }}</td>
                  } @if (visibleColumns.india_Queen.TODO){
                  <td>
                    <span title="Submit HalfHour Order to MAID" [class]="item.bgColor" class="todo"
                      (click)="openModal(modalToOpen, item, false)">{{ calculateINDQUETODO(item) }}</span>
                  </td>
                  } @if (visibleColumns.india_Queen.SUBMITTED){
                  <td>
                    <span [class]="item.indQ_submitted_badge" (click)="openOrderModel(modalToOrderOpen, item)"
                      class="todo">{{ convertValue(item.indQ_Submitted) }}</span>
                  </td>
                  } @if (visibleColumns.india_Queen.PENDING){
                  <td>{{ convertValue(item.indQ_PendingQty) }}</td>
                  }
                </tr>
                } @if (dashboardList?.length == 0) {
                <tr>
                  <td colspan="7" class="msg">No Records Found!</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    } @else if (activeLink==='retail') {
      <!--RETAIL Dashboard Listing -->
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="row">
            <div class="col-md-5">
              <h3>
                Last updated time ({{
                lastSyncDateTime | date : "dd/MM/yyyy, hh:mm a"
                }})
              </h3>
            </div>
            <div class="col-md-4">
              <!-- <form [formGroup]="columnForm">
                <nj-multi-select [isFloatingLabel]="true" formControlName="columns">
                  <ng-container njSelectOptions>
                    <li nj-list-item>TODO</li>
                    <li nj-list-item>SUBMITTED</li>
                    <li nj-list-item>PENDING</li>
                  </ng-container>
                  <ng-container njFormLabel>Hide INDIAN QUEENS Columns</ng-container>
                </nj-multi-select>
              </form> -->
            </div>
            <div class="col-md-3 btn">
              <button type="button" (click)="onUpdate()" [disabled]="isUpdateLoading" class="nj-btn">
                Update ALL
                <span aria-live="polite" aria-atomic="true" [class.nj-spinner]="isUpdateLoading"
                  [class.nj-spinner--inverse]="isUpdateLoading"
                  [class.nj-spinner--xs]="isUpdateLoading"></span></button>&nbsp;&nbsp;
              <!-- <button type="button" (click)="openMELSELModel(modalToMELSELOpen)" class="nj-btn">
                MEL & SEL </button> -->
            </div>
          </div>
        </div>

        <div class="panel-body mainrow">
          <hr />
          <div class="row row-header1">
            <div class="col-md-12">
              <table class="nj-table nj-table--hover">
                <colgroup>
                  <col id="hhTH" span="3" />
                  <col id="retailTH" span="8" />
                </colgroup>
                <thead>
                  <tr>
                    <th id="hhTHBg" colspan="3">HH local Date/Time</th>

                    <th id="saltTHBg" [attr.colspan]="indQueen_positionsColumnsCount">Positions and orders</th>
                  </tr>
                  <tr>
                    <th>DATETIME</th>
                    <th>Block</th>
                    <th>PERIOD</th>
                    <th>TODO</th>
                    <th>SUBMITTED</th>
                    <th>PENDING</th>

                  </tr>
                </thead>
                <tbody>
                  @for (item of dashboardList; track item) {
                  <tr [class]="getRowClass(item)">
                    <td scope="row">{{ item.settlementDate }}</td>
                    <td>
                      <span title="Submit Block2H Order to MAID" class="todo"
                        (click)="openModal(modalToOpen, item, true)">
                        {{ item.block }}
                      </span>
                    </td>
                    <td>{{ item.period }}</td>
                    <td>
                      <span title="Submit HalfHour Order to MAID" [class]="item.bgColor" class="todo"
                        (click)="openModal(modalToOpen, item, false)">{{ calculateRETAILTODO(item) }}</span>
                    </td>
                    <td>
                      <span [class]="item.retaiL_submitted_badge" (click)="openOrderModel(modalToOrderOpen, item)"
                        class="todo">{{ convertValue(item.retaiL_Submitted) }}</span>
                    </td>
                    <td>{{ convertValue(item.retaiL_PendingQty) }}</td>

                  </tr>
                  } @if (dashboardList?.length == 0) {
                  <tr>
                    <td colspan="7" class="msg">No Records Found!</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      }



  </div>

  <ng-template #modalToOpen>
    <nj-modal [hasCloseIcon]="false" modalId="storybook-modal">
      <span njModalTitle>Submit Order</span>
      <p njModalTitle [ngClass]="{
          'nj-badge': true,
          'nj-badge--danger': buySellLabel == 'BUY',
          'nj-badge--success': buySellLabel == 'SELL'
        }">{{ buySellLabel }}</p>
      <span njModalTitle>[{{ orderLabel }}]</span>
      <div njModalContent>
        <app-thermal-position-submit-order [item]="orderObj"></app-thermal-position-submit-order>
      </div>
    </nj-modal>
  </ng-template>

  <ng-template #modalToOrderOpen>
    <nj-modal [hasCloseIcon]="true" modalId="storybook-modal">
      <span njModalTitle>Order List</span>
      <div njModalContent>
        <app-order-details [item]="orderRequest"></app-order-details>
      </div>
    </nj-modal>
  </ng-template>

  <ng-template #modalToMELSELOpen>
    <nj-modal [hasCloseIcon]="true" modalId="storybook-modal">
      <span njModalTitle>MEL & SEL Data
        <!-- ({{
        lastSyncMELSELDateTime | date : "dd/MM/yyyy, hh:mm a"
        }}) -->
        </span>
      <div njModalContent>
        <hr />
        <table id="OrderTable" class="nj-table nj-table--hover">
          <thead>
            <tr>
              <th scope="col">Acceptance Time</th>
              <th scope="col">BMU Name</th>
              <th scope="col">MEL</th>
              <th scope="col">SEL</th>
            </tr>
          </thead>
          <tbody>
            @for (item of melselList; track item) {
            <tr>
              <td>{{ item.acceptanceTime }}</td>
              <td>{{ item.bmuName }}</td>
              <td>{{ item.mel }}</td>
              <td>{{ item.sel }}</td>
            </tr>
            } @if (melselList.length === 0) {
            <tr>
              <td colspan="3" class="msg">{{noDataFound}}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </nj-modal>
  </ng-template>
</section>
