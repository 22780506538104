import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard';
import { CallbackComponent } from './login/callback/callback.component';
import { LogoutComponent } from './login/logout/logout.component';
import { ThermalPositionDashboardComponent } from './features/thermal-position/thermal-position-dashboard/thermal-position-dashboard.component';
import { ThermalPositionOrderListComponent } from './features/thermal-position/thermal-position-order-list/thermal-position-order-list.component';
import { ThermalPositionSubmitOrderComponent } from './features/thermal-position/thermal-position-submit-order/thermal-position-submit-order.component';
import { ThermalPositionConfigurationsComponent } from './features/thermal-position/thermal-position-configurations/thermal-position-configurations.component';
import { ThermalPositionViewOnlyComponent } from './features/thermal-position/thermal-position-view-only/thermal-position-view-only.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/thermal-position/dashboard/saltend',
    pathMatch: 'full',
  },

  {
    path: 'thermal-position/dashboard/saltend',
    component: ThermalPositionDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/view-only/saltend',
    component: ThermalPositionViewOnlyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/order-list/saltend',
    component: ThermalPositionOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/submit-order/saltend',
    component: ThermalPositionSubmitOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/configurations/saltend',
    component: ThermalPositionConfigurationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'thermal-position/dashboard/indqueens',
    component: ThermalPositionDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/view-only/indqueens',
    component: ThermalPositionViewOnlyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/order-list/indqueens',
    component: ThermalPositionOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/submit-order/indqueens',
    component: ThermalPositionSubmitOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/configurations/indqueens',
    component: ThermalPositionConfigurationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'thermal-position/dashboard/retail',
    component: ThermalPositionDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/view-only/retail',
    component: ThermalPositionViewOnlyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/order-list/retail',
    component: ThermalPositionOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/submit-order/retail',
    component: ThermalPositionSubmitOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/configurations/retail',
    component: ThermalPositionConfigurationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/dashboard/hydro',
    component: ThermalPositionDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/order-list/hydro',
    component: ThermalPositionOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/submit-order/hydro',
    component: ThermalPositionSubmitOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'thermal-position/configurations/hydro',
    component: ThermalPositionConfigurationsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'callback', component: CallbackComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
