<section id="triton">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-body mainrow">
        <div class="row">
          <div class="col-md-12 tablespace">
            <div class="row top-btn">
              <span></span>
              <button
              type="button"
              [disabled]="isCancelOrderDisabled"
              (click)="openModal(modalToOpen)"
              class="nj-btn nj-btn--destructive"

            >
              Cancel Order
              <span aria-live="polite" aria-atomic="true"></span>
            </button>
            </div>
            <hr />
            <tbody>
              <table id="OrderTable" class="nj-table nj-table--hover">
                <thead>
                  <tr>
                    <th>
                      <!-- <nj-checkbox
                        [(ngModel)]="allCheck"
                        (valueChange)="onAllOrderSelect($event)"
                      ></nj-checkbox> -->
                    </th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Submitted Qty</th>
                    <th scope="col">Contract</th>
                    <th scope="col">Side</th>
                    <th scope="col">Executed Date</th>
                    <th scope="col">Executed Qty</th>
                    <th scope="col">Executed Price</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  @for (item of paginatedOrderList; track item) {
                  <tr>
                    <td>


                      <ng-container *ngIf="item.maiD_ORDER_STATUS == 'Active' || item.maiD_ORDER_STATUS == 'Created' || item.maiD_ORDER_STATUS == 'Partially Filled'; else dash" >
                        <nj-checkbox
                        [(ngModel)]="item.isChecked"
                        (valueChange)="onOrderSelect($event, item)"
                      ></nj-checkbox>
                      </ng-container>
                      <ng-template #dash><b> - </b></ng-template>



                    </td>
                    <td>{{ item.maiD_ORDER_ID }}</td>
                    <td>{{ item.createD_DATE | date : "dd-MMM-yyyy" }}</td>
                    <td>{{ item.ordereD_QTY | number : "1.1-1" }}</td>
                    <td>{{ item.contract }}</td>
                    <td>
                      <span
                        [ngClass]="{
                          'nj-badge': true,
                          'nj-badge--danger': item.direction == 'Buy',
                          'nj-badge--success': item.direction == 'Sell'
                        }"
                        >{{ item.direction }}</span
                      >
                    </td>
                    <td>
                      {{ item.executioN_DATETIME | date : "dd-MMM-yyyy" }}
                    </td>
                    <td>{{ item.executeD_QTY | number : "1.1-1" }}</td>
                    <td>
                      {{
                        item.executeD_PRICE | number : "1.1-1" | currency : "£"
                      }}
                    </td>
                    <td>
                      <span [class]="getStatusClass(item.maiD_ORDER_STATUS)">{{
                        item.maiD_ORDER_STATUS | uppercase
                      }}</span>
                    </td>
                  </tr>
                  } @if (paginatedOrderList.length === 0) {
                  <tr>
                    <td colspan="8" class="msg">No Records Found!</td>
                  </tr>
                  }
                </tbody>
              </table>
              <span class="label"
                ><b>Total Records :&nbsp; {{ orderList.length }}</b></span
              >
              <nj-pagination
                [count]="totalPages"
                [shouldMinify]="true"
                (selectedItem)="selectedItem($event)"
              >
              </nj-pagination>
            </tbody>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #modalToOpen>
  <nj-modal [hasCloseIcon]="true" modalId="storybook-modal">
    <!-- <span njModalTitle>Cancel Order</span> -->
    <div njModalContent style="text-align: center">
      <span
        aria-hidden="true"
        class="material-icons nj-icon-material nj-icon-material--orange nj-icon-material--xxl nj-modal__icon"
        >info</span
      >
      <p>Are you sure you want to cancel this order?</p>
    </div>
    <ng-container njModalFooter style="justify-content: center">
      <nj-button emphasis="subtle" (buttonClick)="closeModal()">No</nj-button>
      <nj-button [isDisabled]="isCancelled"  (buttonClick)="cancelOrder()">Yes!</nj-button>
    </ng-container>
  </nj-modal>
</ng-template>
