<section id="triton">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-2"><h3>Manage Orders</h3></div>
          <div class="col-md-10">
            <form [formGroup]="filterForm">
              <div class="row row-center">
                <div class="col-md-2 multi-select">
                  <nj-multi-select
                    [isFloatingLabel]="true"
                    formControlName="orderStatus"
                  >
                    <ng-container njSelectOptions>
                      <li
                        nj-list-item
                        *ngFor="let sa of orderStatusList"
                        [value]="sa.lookupValue"
                      >
                        {{ sa.lookupValue | uppercase }}
                      </li>
                    </ng-container>
                    <ng-container njFormLabel>Select Status</ng-container>
                  </nj-multi-select>
                </div>
                <div class="col-md-2">
                  <nj-form-item
                    [hasError]="false"
                    [hasHint]="false"
                    [hasCustomIcon]="false"
                    [isFloatingLabel]="true"
                    iconName=""
                    inputId="orderId"
                  >
                    <input
                      type="text"
                      id="orderId"
                      formControlName="orderId"
                      njFormField
                    />
                    <ng-container njFormLabel>Order Id</ng-container>
                    <ng-container njFormSubscript></ng-container>
                  </nj-form-item>
                </div>
                <div class="col-md-2">
                  <nj-form-item
                    [hasError]="false"
                    [hasHint]="false"
                    [hasCustomIcon]="false"
                    [isFloatingLabel]="true"
                    iconName=""
                    inputId="RDRBDateFrom"
                  >
                    <input
                      type="date"
                      id="RDRBDateFrom"
                      formControlName="fromDate"
                      njFormField
                    />
                    <ng-container njFormLabel>Date From</ng-container>
                    <ng-container njFormSubscript></ng-container>
                  </nj-form-item>
                </div>
                <div class="col-md-2">
                  <nj-form-item
                    [hasError]="false"
                    [hasHint]="false"
                    [hasCustomIcon]="false"
                    [isFloatingLabel]="true"
                    iconName=""
                    inputId="RDRBDateTo"
                  >
                    <input
                      type="date"
                      id="RDRBDateTo"
                      formControlName="toDate"
                      njFormField
                    />
                    <ng-container njFormLabel>Date To</ng-container>
                    <ng-container njFormSubscript></ng-container>
                  </nj-form-item>
                </div>
                <div class="col-md-4">
                  <button
                    type="button"
                    [disabled]="isFilterLoading"
                    (click)="onFilter()"
                    class="nj-btn"
                  >
                    Filter
                    <span
                      aria-live="polite"
                      aria-atomic="true"
                      [class.nj-spinner]="isFilterLoading"
                      [class.nj-spinner--inverse]="isFilterLoading"
                      [class.nj-spinner--xs]="isFilterLoading"
                    ></span>
                  </button>
                  <button
                    type="button"
                    [disabled]="isFilterLoading"
                    (click)="onReset()"
                    class="nj-btn"
                  >
                    <i class="material-icons">refresh</i>
                    <span
                      aria-live="polite"
                      aria-atomic="true"
                      [class.nj-spinner]="isFilterLoading"
                      [class.nj-spinner--inverse]="isFilterLoading"
                      [class.nj-spinner--xs]="isFilterLoading"
                    ></span>
                  </button>
                  <button *ngIf="canSubmitOrders"
                    type="button"
                    [disabled]="isCancelOrderDisabled"
                    (click)="openModal(modalToOpen)"
                    class="nj-btn nj-btn--destructive"
                  >Cancel Order
                    <span
                      aria-live="polite"
                      aria-atomic="true"
                    ></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="panel-body mainrow">
        <div class="row">
          <div class="col-md-12 tablespace">
            <hr />
            <tbody>
              <table id="OrderTable" class="nj-table nj-table--hover">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" (click)="sort('maiD_ORDER_ID')">
                      Order Id @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('externaL_ID')">
                      User Id @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('fullName')">
                      Full Name @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('createD_DATE')">
                      Order Date @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('deliveryPeriod')">
                      Delivery Area @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('direction')">
                      Side @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('ordereD_QTY')">
                      Submitted Qty @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('ordereD_PRICE')">
                      Submitted Price @if (!getOrderIcon()) {<span>▲</span>}
                      @else {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('maiD_ORDER_STATUS')">
                      Status @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('contract')">
                      Contract @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('product')">
                      Product @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('balancinG_GROUP')">
                      Balancing Group @if (!getOrderIcon()) {<span>▲</span>}
                      @else {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('executioN_STRATEGY')">
                      Execution Strategy @if (!getOrderIcon()) {<span>▲</span>}
                      @else {<span>▼</span>}
                    </th>
                    <!-- <th scope="col" (click)="sort('maiD_TRADE_ID')">Execution ID @if (!getOrderIcon()) {<span>▲</span>} @else {<span>▼</span>}</th> -->
                    <th scope="col" (click)="sort('executeD_QTY')">
                      Executed Qty @if (!getOrderIcon()) {<span>▲</span>} @else
                      {<span>▼</span>}
                    </th>
                    <th scope="col" (click)="sort('executeD_PRICE')">
                      Executed Price @if (!getOrderIcon()) {<span>▲</span>}
                      @else {<span>▼</span>}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  @for (item of paginatedOrderList; track item) {
                  <tr>
                    <td>
                      <ng-container *ngIf="canSubmitOrders && (item.maiD_ORDER_STATUS == 'Active' || item.maiD_ORDER_STATUS == 'Created' || item.maiD_ORDER_STATUS == 'Partially Filled'); else dash" >
                        <nj-checkbox [(ngModel)]="item.isChecked" (valueChange)="onOrderSelect($event, item)"></nj-checkbox>
                      </ng-container>
                      <ng-template #dash><b> - </b></ng-template>


                      </td>
                    <td scope="row">{{ item.maiD_ORDER_ID }}</td>
                    <td>{{ item.externaL_ID }}</td>
                    <td>{{ item.fullname }}</td>
                    <td>{{ item.createD_DATE | date : "dd-MMM-yyyy" }}</td>
                    <td>{{ item.grid }}</td>
                    <td>
                      <span
                        [ngClass]="{
                          'nj-badge': true,
                          'nj-badge--danger': item.direction == 'Buy',
                          'nj-badge--success': item.direction == 'Sell'
                        }"
                        >{{ item.direction }}</span
                      >
                    </td>
                    <td>{{ item.ordereD_QTY | number : "1.1-1" }}</td>
                    <td>
                      {{
                        item.ordereD_PRICE | number : "1.1-1" | currency : "£"
                      }}
                    </td>
                    <td>
                      <span
                        style="width: 120px"
                        [class]="getStatusClass(item.maiD_ORDER_STATUS)"
                        >{{ item.maiD_ORDER_STATUS | uppercase }}</span
                      >
                    </td>
                    <td>{{ item.contract }}</td>
                    <td>{{ item.product }}</td>
                    <td>{{ item.balancinG_GROUP }}</td>
                    <td>{{ item.executioN_STRATEGY }}</td>
                    <!-- <td>{{ item.maiD_TRADE_ID }}</td> -->
                    <td>{{ item.executeD_QTY | number : "1.1-1" }}</td>
                    <td>
                      {{
                        item.executeD_PRICE | number : "1.1-1" | currency : "£"
                      }}
                    </td>
                  </tr>
                  } @if (paginatedOrderList.length === 0) {
                  <tr>
                    <td colspan="16" class="msg">No Records Found!</td>
                  </tr>
                  }
                </tbody>
              </table>
              <span class="label"
                ><b>Total Records :&nbsp; {{ orderList.length }}</b></span
              >
              <nj-pagination
                [count]="totalPages"
                [shouldMinify]="true"
                (selectedItem)="selectedItem($event)"
              ></nj-pagination>
            </tbody>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #modalToOpen>
  <nj-modal [hasCloseIcon]="false" modalId="storybook-modal">
     <!-- <span njModalTitle>Cancel Order</span> -->
     <div njModalContent style="text-align: center;">
      <span aria-hidden="true" class="material-icons nj-icon-material nj-icon-material--orange nj-icon-material--xxl nj-modal__icon">info</span>
         <p>Are you sure you want to cancel this order?</p>
     </div>
     <ng-container njModalFooter style="justify-content: center;">
         <nj-button [isDisabled]="isCancelled" emphasis="subtle" (buttonClick)="closeModal()">No</nj-button>
         <nj-button [isDisabled]="isCancelled" (buttonClick)="cancelOrder()">Yes!</nj-button>
     </ng-container>
 </nj-modal>
</ng-template>

