export class DashboardResponse {
  public settlementDate: string = '';
  public block: string = '';
  public hiddenBlock: string = '';
  public period: number = 0;
  public saL_U1: number = 0;
  public saL_U2: number = 0;
  public saL_U3: number = 0;
  public saL_U4: number = 0;
  public saL_Cont: number = 0;
  public saL_TODO: number = 0;
  public saL_Submitted: number = 0;
  public saL_Submitted_Display: number = 0;
  public sal_submitted_badge: string = '';
  public saL_Reqd: number = 0;
  public indQ_U1: number = 0;
  public indQ_Cont: number = 0;
  public indQ_TODO: number = 0;
  public indQ_Submitted: number = 0;
  public indQ_Submitted_Display: number = 0;
  public indQ_submitted_badge: string = '';
  public bgColor: string = '';
  public saL_TODO_Display: number = 0;
  public saL_Reqd_Display: number = 0;
  public saL_Cont_Display: number = 0;
  public indQ_Cont_Display: number = 0;
  public indQ_TODO_Display: number = 0;
  public timeFrom: string = '';
  public executedQty: number = 0;
  public pendingQty: number = 0;
  public mel: string = '';
  public sel: string = '';
  public indQ_ExecutedQty: number = 0;
  public indQ_PendingQty: number = 0;
  public asset_Type: string = '';

  public retaiL_TODO: number = 0;
  public retaiL_Submitted: number = 0;
  public retail_TODO_Display: number = 0;
  public retaiL_ExecutedQty: number = 0;
  public retaiL_PendingQty: number = 0;
  public retaiL_submitted_badge: string = '';
}
