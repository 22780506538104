import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ThermalPositionService } from 'src/app/core/services/thermal-position.service';

@Component({
  selector: 'app-thermal-position-configurations',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './thermal-position-configurations.component.html',
  styleUrl: './thermal-position-configurations.component.scss',
})
export class ThermalPositionConfigurationsComponent {
  isSaltendOrIndQueen: boolean = true;
  appConfigData: any;
  appConfigObj: any = [];
  activeLink : any;
  constructor(private thermalPositionService: ThermalPositionService) {}

  ngOnInit(): void {

    this.activeLink = localStorage.getItem('activeAssetType');
    this.getAppConfigData();
  }

  getAppConfigData() {
    this.thermalPositionService.getAppConfigData().subscribe((res) => {
      this.appConfigData = res;
      if (this.appConfigData) {
        this.appConfigObj = this.appConfigData.reduce(
          (
            acc: { [x: string]: any },
            item: { configName: string; configValue: any }
          ) => {
            acc[item.configName.toLowerCase()] = item.configValue;
            return acc;
          },
          {}
        );
      }
    });
  }
}
