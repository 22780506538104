import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LookupResponse } from '../Dtos/lookup.entity';
import { DashboardResponse } from '../Dtos/dashboard.entity';
import { OrderList } from '../Dtos/order-list.entity';
import { FilterRequest } from '../Dtos/filter.entity';
import { AppConfigDataResponse } from '../Dtos/appconfig.entity';
import { BaseHttpApiService } from './base-http.service';
import { MELSELResponse } from '../Dtos/melsel.entity';
import { DutyAllocationUnitSpad } from '../Dtos/dutyAllocationUnitSpad';

@Injectable({
  providedIn: 'root',
})

export class ThermalPositionService extends BaseHttpApiService {
  private LookupDataUrl = 'getMAIDLookupList';
  private DashboardDataUrl = 'getSaltendDashboardData';
  private HydroDashboardDataUrl = 'getHydroThermalPositionList';
  private ThermalPositionViewOnlyDataUrl = 'getThermalPositionViewOnly';
  private FilterOrderListUrl = 'getMAIDOrderList';
  private AppConfigDataUrl = 'getApplicationConfigList';
  private OrderListUrl = 'getOrderListByOrder';
  private MELSELUrl = 'getMelSELData';
  private DutyAllocationDataUrl = 'getUnitsDutyAllocations_SPAD';
  private RefreshOrders = 'refreshOrders';
  private AddOrderUrl = 'addMultipleOrders';
  private CancelOrderUrl = 'CancelMultipleOrders';

  constructor(private http: HttpClient) {
    super();
  }

  getSignalrHubURL() {
    return this.maidConnectorApiURL + 'MAIDOrderHub';
  }

  getLookupResponse(): Observable<LookupResponse[]> {
    return this.http
      .get<LookupResponse[]>(this.apiURL + this.LookupDataUrl)
      .pipe(
        map((data: LookupResponse[]) => {
          return this.automMapToClass(LookupResponse, data);
        })
      );
  }

  getMELSEL(): Observable<MELSELResponse[]> {
    return this.http.get<MELSELResponse[]>(this.apiURL + this.MELSELUrl).pipe(
      map((data: MELSELResponse[]) => {
        return this.automMapToClass(MELSELResponse, data);
      })
    );
  }

  getUnitsDutyAllocations_SPAD(): Observable<DutyAllocationUnitSpad[]> {
    return this.http.get<DutyAllocationUnitSpad[]>(this.apiURL + this.DutyAllocationDataUrl).pipe(
      map((data: DutyAllocationUnitSpad[]) => {
        return this.automMapToClass(DutyAllocationUnitSpad, data);
      })
    );
  }

  getDashboardList(): Observable<DashboardResponse[]> {
    return this.http
      .get<DashboardResponse[]>(this.apiURL + this.DashboardDataUrl)
      .pipe(
        map((data: DashboardResponse[]) => {
          return this.automMapToClass(DashboardResponse, data);
        })
      );
  }

  getHydroDashboardList(): Observable<DashboardResponse[]> {
    return this.http
      .get<DashboardResponse[]>(this.apiURL + this.HydroDashboardDataUrl)
      .pipe(
        map((data: DashboardResponse[]) => {
          return this.automMapToClass(DashboardResponse, data);
        })
      );
  }

  getThermalPositionViewOnlyList(): Observable<DashboardResponse[]> {
    return this.http
      .get<DashboardResponse[]>(this.apiURL + this.ThermalPositionViewOnlyDataUrl)
      .pipe(
        map((data: DashboardResponse[]) => {
          return this.automMapToClass(DashboardResponse, data);
        })
      );
  }

  getAppConfigData(): Observable<AppConfigDataResponse[]> {
    return this.http
      .get<AppConfigDataResponse[]>(this.apiURL + this.AppConfigDataUrl)
      .pipe(
        map((data: AppConfigDataResponse[]) => {
          return this.automMapToClass(AppConfigDataResponse, data);
        })
      );
  }

  automMapToClass<T>(cls: new () => T, data: any[]): T[] {
    return data.map((item) => {
      const obj = new cls();
      for (const key in obj) {
        if (item.hasOwnProperty(key)) {
          obj[key] = item[key];
        }
      }
      return obj;
    });
  }

  filterOrderList(request: FilterRequest): Observable<OrderList[]> {
    let params = new HttpParams()
      .set('OrderStatus', request.orderStatus)
      .set('OrderId', request.orderId)
      .set('TradeId', request.tradeId)
      .set('FromDate', request.fromDate)
      .set('ToDate', request.toDate)
      .set('AssetType', request.assetType);
    return this.http
      .get<OrderList[]>(this.apiURL + this.FilterOrderListUrl, { params })
      .pipe(
        map((data: OrderList[]) => {
          return data;
        })
      );
  }

  submitOrderRequest(request: any) {
    return this.http.post(this.maidConnectorApiURL + this.AddOrderUrl, request);
  }

  cancelOrder(request: any) {
    return this.http.post(this.maidConnectorApiURL + this.CancelOrderUrl, request);
  }

  refreshOrder() {
    return this.http.get(this.maidConnectorApiURL + this.RefreshOrders);
  }

  orderList(request: any) {
    let params = new HttpParams()
      .set('Block', request.block)
      .set('Period', request.period)
      .set('SettlementDate', request.settlementDate)
      .set('AssetType', request.assetType);
    return this.http
      .get<OrderList[]>(this.apiURL + this.OrderListUrl, { params })
      .pipe(
        map((data: OrderList[]) => {
          return data;
        })
      );
  }
}
