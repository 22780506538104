import { Component, ComponentRef, ViewContainerRef } from '@angular/core';
import { ToastService } from '@engie-group/fluid-design-system-angular';
import { AlertMessage } from 'src/app/core/Dtos/alert.entity';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { ToastViewerComponent } from '../toast/toast.component';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public DisplayLoading: boolean = false;
  public CurrentTab: string = '/thermal-position/dashboard';
  activeLink = 'saltend';
  isConfigurationHidden : boolean = false;
  public get UserName(): string {
    return this._authStateService.userName;
  }

  constructor(
    private _authStateService: AuthStateService,
    private _alertService: AlertsService,
    private toastService: ToastService,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    _alertService.onAPICallCountChange.subscribe((m) => {
      console.log('API Calls count: ' + m);
      this.DisplayLoading = m != 0;
    });

    _alertService.onAlertReceived.subscribe((message: AlertMessage) => {
      const toastRef: ComponentRef<ToastViewerComponent> =
        this.toastService.open(ToastViewerComponent, {
          viewContainerRef: this.viewContainerRef,
        });
      toastRef.instance.Title = message.Type.toString();
      toastRef.instance.Message = message.Message;
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url != '/' && !val.url.includes('/callback')) {
          const urlSegments = val.urlAfterRedirects.split('/');
          this.activeLink = urlSegments[urlSegments.length - 1];
          val.url = val.url.substring(0, val.url.lastIndexOf('/'));
          this.CurrentTab = val.url;
        }
        if(this.activeLink==='retail'){
          this.isConfigurationHidden= true;
        }else {
          this.isConfigurationHidden= false;
        }
        localStorage.setItem('activeAssetType', this.activeLink);
      }
    });
  }

  LogOff() {
    this._authStateService.logout();
  }

  getActiveTab(link: string) {
    this.activeLink = link;
    this.NavigateTo(this.CurrentTab);
  }

  NavigateTo(url: string) {
    this.CurrentTab = url;
    this.router.navigate([this.CurrentTab + '/' + this.activeLink]);
  }
}
