export class DashboardResponse {
  public settlementDate: string = '';
  public block: string = '';
  public hiddenBlock: string = '';
  public period: number = 0;
  public saL_U1: number = 0;
  public saL_U2: number = 0;
  public saL_U3: number = 0;
  public saL_U4: number = 0;
  public saL_Cont: number = 0;
  public saL_TODO: number = 0;
  public saL_Submitted: number = 0;
  public saL_Submitted_Display: number = 0;
  public sal_submitted_badge: string = '';
  public saL_Reqd: number = 0;
  public indQ_U1: number = 0;
  public indQ_Cont: number = 0;
  public indQ_TODO: number = 0;
  public indQ_Submitted: number = 0;
  public indQ_Submitted_Display: number = 0;
  public indQ_submitted_badge: string = '';
  public bgColor: string = '';
  public saL_TODO_Display: number = 0;
  public saL_Reqd_Display: number = 0;
  public saL_Cont_Display: number = 0;
  public indQ_Cont_Display: number = 0;
  public indQ_TODO_Display: number = 0;
  public timeFrom: string = '';
  public executedQty: number = 0;
  public pendingQty: number = 0;
  public mel: string = '';
  public sel: string = '';
  public indQ_ExecutedQty: number = 0;
  public indQ_PendingQty: number = 0;
  public asset_Type: string = '';

  public retaiL_TODO: number = 0;
  public retaiL_Submitted: number = 0;
  public retail_TODO_Display: number = 0;
  public retaiL_ExecutedQty: number = 0;
  public retaiL_PendingQty: number = 0;
  public retaiL_submitted_badge: string = '';

  public hydrO_DINO1: number = 0;
  public hydrO_DINO2: number = 0;
  public hydrO_DINO3: number = 0;
  public hydrO_DINO4: number = 0;
  public hydrO_DINO5: number = 0;
  public hydrO_DINO6: number = 0;
  public hydrO_FFES1: number = 0;
  public hydrO_FFES2: number = 0;
  public hydrO_FFES3: number = 0;
  public hydrO_FFES4: number = 0;

  public hydrO_TODO: number = 0;
  public hydrO_IMBAL_TODO: number = 0;
  public hydrO_SID_NCP_CONTRACTED: number = 0;

  public hydrO_Losses: number = 0;
  public hydrO_Req_Gen: number = 0;
  public hydrO_SPIN_Gen: number = 0;

  public hydrO_Losses_Display: number = 0;
  public hydrO_Req_Gen_Display: number = 0;
  public hydrO_SPIN_Gen_Display: number = 0;

  public hydrO_SID_NCP_CONTRACTED_Display: number = 0;
  public hydrO_PNS_REQUIRED: number = 0;
  public hydrO_PNS_REQUIRED_Display: number = 0;
  public hydrO_Submitted: number = 0;
  public hydro_TODO_Display: number = 0;
  public hydrO_ExecutedQty: number = 0;
  public hydrO_PendingQty: number = 0;
  public hydrO_submitted_badge: string = '';

  public hydrO_DINO1_HexColor: string = '';
  public hydrO_DINO2_HexColor: string = '';
  public hydrO_DINO3_HexColor: string = '';
  public hydrO_DINO4_HexColor: string = '';
  public hydrO_DINO5_HexColor: string = '';
  public hydrO_DINO6_HexColor: string = '';
  public hydrO_FFES1_HexColor: string = '';
  public hydrO_FFES2_HexColor: string = '';
  public hydrO_FFES3_HexColor: string = '';
  public hydrO_FFES4_HexColor: string = '';
  public hydrO_TODO_HexColor: string = '';
}
