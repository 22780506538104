<nav class="nj-navbar nj-navbar--shadow nj-navbar--expand-xl">
  <a class="nj-navbar__brand" href="#">
    <img src="assets/logo-engie-blue.svg" class="nj-navbar__logo" />
  </a>
  <button
    class="nj-navbar__toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarExample"
  >
    <i class="nj-navbar__toggler-icon material-icons">menu</i>
  </button>
  <div class="nj-navbar--collapse nj-collapse" id="navbarExample">
    <ul class="nj-navbar__nav ml-auto">
      <li class="nj-navbar__nav-item">
        <a
          (click)="getActiveTab('saltend')"
          [ngClass]="{ active: activeLink === 'saltend' }"
          class="nj-navbar__nav-link"
          >SALTEND</a
        >
      </li>
      <li class="nj-navbar__nav-item">
        <a
          (click)="getActiveTab('indqueens')"
          [ngClass]="{ active: activeLink === 'indqueens' }"
          class="nj-navbar__nav-link"
          >INDIAN QUEENS</a
        >
      </li>
      <li class="nj-navbar__nav-item">
        <a
          (click)="getActiveTab('retail')"
          [ngClass]="{ active: activeLink === 'retail' }"
          class="nj-navbar__nav-link">RETAIL</a
        >
      </li>
      <li class="nj-navbar__nav-item">
        <a
          (click)="getActiveTab('hydro')"
          [ngClass]="{ active: activeLink === 'hydro' }"
          class="nj-navbar__nav-link"

          >HYDRO</a
        >
      </li>
      <li class="nj-navbar__nav-item">
        <a
          class="nj-navbar__nav-link nj-navbar__nav-link--icon"
          (click)="LogOff()"
          href="#"
          ><span
            aria-hidden="true"
            class="material-icons nj-icon-material nj-icon-material--brand"
            >power_settings_new</span
          ><span class="nj-sr-only">Nav item</span></a
        >
      </li>
      <li class="nj-navbar__nav-item">
        <div id="userInfo">
          <span>{{ UserName }}</span>
        </div>
      </li>
    </ul>
  </div>
</nav>

<div
  style="padding: 16px; font-family: var(--nj-semantic-font-family-default)"
  [style.background]="'#2b3d83'"
  [style.display]="DisplayLoading ? '' : 'none'"
>
  <nj-spinner variant="inverse" size="xxs" [isLoading]="true"></nj-spinner>
  <div style="color: white; float: left">Loading ...&nbsp;&nbsp;&nbsp;</div>
</div>
<div class="col-md-12 text-right">
  <nj-tabs>
    <nj-tab
      (tabSelect)="NavigateTo('/thermal-position/dashboard')"
      [isActive]="CurrentTab == '/thermal-position/dashboard'"
    >
      <ng-container>Dashboard</ng-container>
    </nj-tab>
    <nj-tab [hidden]="isPositionViewnHidden"
    (tabSelect)="NavigateTo('/thermal-position/view-only')"
    [isActive]="CurrentTab == '/thermal-position/view-only'"
  >
    <ng-container>Position View</ng-container>
  </nj-tab>
  <!-- <nj-tab [hidden]="!isPositionViewnHidden"
    (tabSelect)="NavigateTo('/thermal-position/sic-view-only')"
    [isActive]="CurrentTab == '/thermal-position/sic-view-only'"
  >
    <ng-container>SIC View</ng-container>
  </nj-tab> -->
    <nj-tab
      (tabSelect)="NavigateTo('/thermal-position/order-list')"
      [isActive]="CurrentTab == '/thermal-position/order-list'"
    >
      <ng-container>Manage Orders</ng-container>
    </nj-tab>
    <nj-tab [hidden]="isConfigurationHidden"
      (tabSelect)="NavigateTo('/thermal-position/configurations')"
      [isActive]="CurrentTab == '/thermal-position/configurations'"
    >
      <ng-container>Configurations</ng-container>
    </nj-tab>
  </nj-tabs>
</div>
