import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DoCheck,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { ThermalPositionService } from 'src/app/core/services/thermal-position.service';
import { NavigationEnd, Router } from '@angular/router';
import { format, parse } from 'date-fns';


@Component({
  selector: 'app-thermal-position-view-only',
  templateUrl: './thermal-position-view-only.component.html',
  styleUrl: './thermal-position-view-only.component.scss'
})
export class ThermalPositionViewOnlyComponent implements OnInit  {

  activeLink : any = '';
  appConfigData: any;
  appConfigObj: any = [];
  dashboardList: any;
  lastSyncDateTime: any;
  badgeClass: any = [
    'nj-badge nj-badge--danger',
    'nj-badge nj-badge--success',
    'nj-badge nj-badge--warning',
    'nj-badge nj-badge--primary',
  ];
  assetTypeList = ['SALTEND', 'INDQUEENS','RETAIL'];
  isUpdateLoading: boolean = false;
  saltend_positionsColumnsCount :number =4;
  saltend_unitsColumnCount :number =5;

  indQueen_positionsColumnsCount :number =4;
  indQueen_unitsColumnCount :number =1;

  constructor(
    private thermalPositionService: ThermalPositionService,
    private cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    this.getAppConfigData();
  }


  ngOnInit(): void {
    this.activeLink = localStorage.getItem('activeAssetType');
   // this.createForm();
    //this.getLookupList();
   // this.callSignalr();
     this.checkDashboardList(true);
    // this.columnForm.get('columns')?.valueChanges.subscribe((selectedValues: any) => {
    //   this.selectedColumns = selectedValues.map((selectedValue: any) => {
    //     const element = document.querySelector(`li[data-value="${selectedValue}"]`);
    //     return {
    //       value: selectedValue,
    //       category: element?.getAttribute('data-category'),
    //     };
    //   });
    //   this.onColumnChange();
    // });
    // this.cdr.detectChanges();
  }

  formatDate(date: string) {
    let settlementDate = parse(date, 'dd/MM/yyyy HH:mm:ss', new Date());
    let sDate = format(settlementDate, 'dd-MM-yyyy');
    return sDate;
  }

  convertValue(value: number) {
    if (value != 0) {
      return Math.round(value);
    }
    return value;
  }
  getAppConfigData() {
    this.thermalPositionService.getAppConfigData().subscribe((res) => {
      this.appConfigData = res;
      if (this.appConfigData) {
        this.appConfigObj = this.appConfigData.reduce(
          (
            acc: { [x: string]: any },
            item: { configName: string; configValue: any }
          ) => {
            acc[item.configName.toLowerCase()] = item.configValue;
            return acc;
          },
          {}
        );
        this.cdr.detectChanges();
      }
    });
  }




  getRowClass(item: any): string {
    if (item.block) {
      return 'lightblue';
    } else {
      return '';
    }
  }

  checkDashboardList(isDefaultLoad: boolean) {
    if (isDefaultLoad) {
      const storedDashboardList = localStorage.getItem('readOnlydashboardList');
      const lastSyncDateTime = localStorage.getItem('readOnlylastSyncDateTime');

      if (lastSyncDateTime) {
        let lastSyncTime = new Date(lastSyncDateTime).getTime();
        let currentTime = new Date().getTime();
        let diff = currentTime - lastSyncTime;

        if (diff >= 600000) {
          localStorage.removeItem('readOnlydashboardList'); //remove after 10 min
          localStorage.removeItem('readOnlylastSyncDateTime');
          this.getDashboardList();
        } else {
          if (storedDashboardList) {
            this.dashboardList = JSON.parse(storedDashboardList);
            this.isUpdateLoading = false;

            this.lastSyncDateTime = lastSyncDateTime
              ? lastSyncDateTime
              : Date();
          } else {
            this.getDashboardList();
          }
        }
      } else {
        this.getDashboardList();
      }

    } else {
      this.getDashboardList();
    }
  }

  getDashboardList() {
    this.thermalPositionService.getThermalPositionViewOnlyList().subscribe((res) => {
      if (res) {
        this.dashboardList = res;
        this.lastSyncDateTime = Date();
        localStorage.setItem('readOnlylastSyncDateTime', this.lastSyncDateTime);
        this.dashboardList?.forEach(
          (data: {
            saL_Submitted: number;
            indQ_Submitted: number;
            sal_submitted_badge: any;
            indQ_PendingQty: number;
            pendingQty: number;
            indQ_submitted_badge: any;
            retaiL_Submitted:number;
            retaiL_submitted_badge : any;
            retaiL_PendingQty:number;
          }) => {


            if(this.activeLink==='saltend'){
              if (data.pendingQty == 0 && data.saL_Submitted != 0) {
                data.sal_submitted_badge = this.badgeClass[1];
              } else if (data.pendingQty > 0 && data.saL_Submitted != 0) {
                data.sal_submitted_badge = this.badgeClass[2];
              } else {
                data.sal_submitted_badge = '';
              }

            }else if(this.activeLink==='indqueens'){
              if (data.indQ_PendingQty == 0 && data.indQ_Submitted != 0) {
                data.indQ_submitted_badge = this.badgeClass[1];
              } else if (data.indQ_PendingQty > 0 && data.indQ_Submitted != 0) {
                data.indQ_submitted_badge = this.badgeClass[2];
              } else {
                data.indQ_submitted_badge = '';
              }

            } else if(this.activeLink==='retail'){
              if (data.retaiL_PendingQty == 0 && data.retaiL_Submitted != 0) {
                data.retaiL_submitted_badge = this.badgeClass[1];
              } else if (data.retaiL_PendingQty > 0 && data.retaiL_Submitted != 0) {
                data.retaiL_submitted_badge = this.badgeClass[2];
              } else {
                data.retaiL_submitted_badge = '';
              }

            }
            //this.cdr.detectChanges();
          }
        );

        this.isUpdateLoading = false;
        localStorage.setItem(
          'readOnlydashboardList',
          JSON.stringify(this.dashboardList)
        );
      } else {
        this.isUpdateLoading = false;
      }
    });
  }



  calculateTODO(item: any) {
    let x = 0;
    if (item.saL_U1 == 0) x++;
    if (item.saL_U2 == 0) x++;
    if (item.saL_U3 == 0) x++;

    x =
      x == 1
        ? parseFloat(this.appConfigObj.saltend_two_units_on)
        : x == 2 || x == 3
        ? parseFloat(this.appConfigObj.saltend_no_units_on)
        : x;
    item.saL_TODO = item.saL_Reqd - item.saL_Cont - x;

    if (item.saL_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.saL_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    item.saL_TODO = parseFloat(item.saL_TODO.toFixed(2));
    item.saL_TODO_Display =
      item.saL_TODO > -1 && item.saL_TODO < 1 ? 0 : Math.round(item.saL_TODO);
    item.bgColor =
      item.saL_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.saL_TODO_Display; //* (item.saL_TODO_Display < 0 ? -1 : 1);
  }

  calculateINDQUETODO(item: any) {
    const indQ_U1 = item.indQ_U1 ? parseFloat(item.indQ_U1) : 0;
    item.indQ_TODO =
      indQ_U1 * this.appConfigObj.indquen_iq_transmission -
      (item.indQ_Cont ? parseFloat(item.indQ_Cont) : 0);

    if (item.indQ_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.indQ_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    item.indQ_TODO_Display =
      item.indQ_TODO > -1 && item.indQ_TODO < 1
        ? 0
        : Math.round(parseFloat(item.indQ_TODO));
    item.bgColor =
      item.indQ_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.indQ_TODO_Display; // * (item.indQ_TODO_Display < 0 ? -1 : 1);
  }

  calculateRETAILTODO(item: any) {
    if (item.retaiL_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.retaiL_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    item.retail_TODO_Display =
      item.retaiL_TODO > -1 && item.retaiL_TODO < 1
        ? 0
        : Math.round(parseFloat(item.retaiL_TODO));
    item.bgColor =
      item.retail_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.retail_TODO_Display;
  }

  onUpdate() {
    this.isUpdateLoading = true;
    this.checkDashboardList(false);
  }



  getSalCount(item: any) {
    item.saL_Cont_Display = Math.round(parseFloat(item.saL_Cont) | 0);
    return item.saL_Cont_Display * (item.saL_Cont_Display < 0 ? -1 : 1);
  }

  calculateREQD(item: any) {
    item.saL_Reqd =
      (parseFloat(item.saL_U1) +
        parseFloat(item.saL_U2) +
        parseFloat(item.saL_U3) +
        parseFloat(item.saL_U4)) *
      this.appConfigObj.erg_tlm;
    item.saL_Reqd = parseFloat(item.saL_Reqd.toFixed(2));
    item.saL_Reqd_Display = Math.round(parseFloat(item.saL_Reqd));
    return item.saL_Reqd_Display * (item.saL_Reqd_Display < 0 ? -1 : 1);
  }

}
