import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  FormItemComponent,
  ButtonComponent,
  FormFieldDirective,
  ToastComponent,
  SelectComponent,
  SpinnerComponent,
  CheckboxComponent,
  PaginationComponent,
  RadioComponent,
  RadioGroupComponent,
  ToastService,
  TabsComponent,
  TabComponent,
  MultiSelectComponent,
  ListItemComponent,
  ModalComponent,
  TooltipComponent,
} from '@engie-group/fluid-design-system-angular';
import { HeaderComponent } from './features/shared/header/header.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  AuthStateService,
  IAM_CONFIG,
} from './core/services/auth-state.service';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth-guard';
import { CallbackComponent } from './login/callback/callback.component';
import { LogoutComponent } from './login/logout/logout.component';
import { APIInterceptor } from './core/api.interceptor';
import { ToastViewerComponent } from './features/shared/toast/toast.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThermalPositionDashboardComponent } from './features/thermal-position/thermal-position-dashboard/thermal-position-dashboard.component';
import { ThermalPositionSubmitOrderComponent } from './features/thermal-position/thermal-position-submit-order/thermal-position-submit-order.component';
import { ThermalPositionOrderListComponent } from './features/thermal-position/thermal-position-order-list/thermal-position-order-list.component';
import { OrderDetailsComponent } from './features/thermal-position/order-details/order-details.component';
import { ThermalPositionViewOnlyComponent } from './features/thermal-position/thermal-position-view-only/thermal-position-view-only.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CallbackComponent,
    LogoutComponent,
    ToastViewerComponent,
    ThermalPositionDashboardComponent,
    ThermalPositionSubmitOrderComponent,
    ThermalPositionOrderListComponent,
    ThermalPositionViewOnlyComponent,
    OrderDetailsComponent,
  ],
  providers: [
    ToastService,
    AuthGuard,
    {
      provide: IAM_CONFIG, // That's the token we defined previously
      useValue: environment.iam, // That's the actual service itself
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    FormItemComponent,
    FormFieldDirective,
    ButtonComponent,
    ToastComponent,
    SelectComponent,
    SpinnerComponent,
    CheckboxComponent,
    PaginationComponent,
    RadioComponent,
    RadioGroupComponent,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    MultiSelectComponent,
    ListItemComponent,
    ModalComponent,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl, environment.MAIDApiURL],
        sendAccessToken: true,
      },
    }),
  ],
})
export class AppModule {}
