export const environment = {
  iam: {
    clientId: '2-b-PosMaster-Portal-PQRAX9XAF8SGAKG',
    issuer: 'https://gem-beta.oktapreview.com/oauth2/aus3v6rg3e9zNHMxe0x6',

    redirectUri: 'https://posmaster.noprod.gems.engie.co.uk/callback',
    logoutUrl: 'https://posmaster.noprod.gems.engie.co.uk/logout',
    postLogoutRedirectUri:
      'https://posmaster.noprod.gems.engie.co.uk/logout',
    responseType: 'code',
    // tslint:disable-next-line: max-line-length
    scope: 'openid profile email api.posmaster api.maidconnector',
    showDebugInformation: true,
    sessionChecksEnabled: true,
    nonceStateSeparator: ';',
    useSilentRefresh: true,
    fallbackAccessTokenExpirationTimeInSec: 3600,
    silentRefreshRedirectUri:
      'https://posmaster.noprod.gems.engie.co.uk/assets/silent-refresh.html',
    tokenEndpoint:
      'https://gem-beta.oktapreview.com/oauth2/aus3v6rg3e9zNHMxe0x6/v1/token',
  },
  production: false,
  MAIDApiURL: 'https://posmasterengiemaid-api.apps.noprod.gems.engie.co.uk',
  apiUrl: 'https://posmaster-api.apps.noprod.gems.engie.co.uk',
};
