import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { format, parse } from 'date-fns';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { ThermalPositionService } from 'src/app/core/services/thermal-position.service';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ToastService } from '@engie-group/fluid-design-system-angular';
import { ToastViewerComponent } from '../../shared/toast/toast.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss',
})
export class OrderDetailsComponent implements OnInit {
  orderList: any = [];
  @Input() item: any;
  @Output() formClosed: EventEmitter<any> = new EventEmitter<any>();
  orderRequest: any;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number = 1;
  paginatedOrderList: any = [];
  isSaltendOrIndQueen: boolean = true;
  cancelOrderRequest: any = [];
  allCheck: boolean = false;
  isCancelOrderDisabled: boolean = true;
  isCancelled : boolean = false;
  orderStatusList = [
    { lookupValue: 'Active', bgcolor: 'nj-badge nj-badge--success' },
    { lookupValue: 'Cancelled', bgcolor: 'nj-badge nj-badge--danger' },
    { lookupValue: 'Closed', bgcolor: 'nj-badge nj-badge--danger' },
    { lookupValue: 'Created', bgcolor: 'nj-badge' },
    {
      lookupValue: 'Created Hibernated',
      bgcolor: 'nj-badge nj-badge--information',
    },
    { lookupValue: 'Filled', bgcolor: 'nj-badge nj-badge--success' },
    { lookupValue: 'Hibernating', bgcolor: 'nj-badge nj-badge--information' },
    {
      lookupValue: 'Partially Filled',
      bgcolor: 'nj-badge nj-badge--discovery',
    },
    { lookupValue: 'Pending', bgcolor: 'nj-badge' },
    { lookupValue: 'Rejected', bgcolor: 'nj-badge nj-badge--danger' },
    { lookupValue: 'Unknown', bgcolor: 'nj-badge' },
  ];
  activeLink : any = '';

  constructor(
    private thermalPositionService: ThermalPositionService,
    private _authStateService: AuthStateService,
    private toastService: ToastService, private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {


    this.activeLink = localStorage.getItem('activeAssetType');

    this.orderRequest = this.item;
    this.getOrderList();
  }

  getOrderList() {
    let date = parse(
      this.orderRequest.settlementDate,
      'dd/MM/yyyy HH:mm:ss',
      new Date()
    );
    let obj = {
      block: this.orderRequest.hiddenBlock,
      period: this.orderRequest.period,
      settlementDate: format(date, 'yyyy-MM-dd'),
      assetType: this.activeLink.toUpperCase()
    };
    this.thermalPositionService.orderList(obj).subscribe((res) => {
      if (res) {
        this.orderList = res;
        this.paginatedOrderList = res;
        this.totalPages = Math.ceil(this.orderList.length / this.pageSize);
        this.updatePaginatedOrderList();
      }
    });
  }

  updatePaginatedOrderList() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedOrderList = this.orderList.slice(start, end);
  }

  selectedItem(event: any) {
    this.currentPage = event;
    this.updatePaginatedOrderList();
  }

  public getStatusClass(item: string) {
    let data = this.orderStatusList.filter(
      (a) => a.lookupValue.toLocaleLowerCase() == item.toLocaleLowerCase()
    );
    return data.length > 0 ? data[0].bgcolor : '';
  }

  onAllOrderSelect(check: any) {
    this.cancelOrderRequest = [];
    if (check) {
      this.allCheck = true;
      this.orderList = this.orderList.map((a: { maiD_ORDER_ID: any }) => {
        let obj = { order_id: a.maiD_ORDER_ID };
        this.cancelOrderRequest.push(obj);
        return { ...a, isChecked: true };
      });
    } else {
      this.allCheck = false;
      this.orderList = this.orderList.map((a: { maiD_ORDER_ID: any }) => {
        return { ...a, isChecked: false };
      });
    }
    this.updatePaginatedOrderList();
    this.isCancelOrderDisabled =
      this.cancelOrderRequest.length > 0 ? false : true;
  }

  onOrderSelect(check: any, data: any) {
    if (check) {
      let checkOrder = this.cancelOrderRequest.filter(
        (a: { order_id: any }) => a.order_id == data.maiD_ORDER_ID
      );
      if (checkOrder.length == 0) {
        let obj = { order_id: data.maiD_ORDER_ID };
        this.cancelOrderRequest.push(obj);
      }
      this.orderList = this.orderList.map((a: { maiD_ORDER_ID: any }) => {
        if (a.maiD_ORDER_ID == data.maiD_ORDER_ID) {
          return { ...a, isChecked: true };
        }
        return a;
      });
    } else {
      this.allCheck = false;
      this.cancelOrderRequest = this.cancelOrderRequest.filter(
        (a: { order_id: any }) => a.order_id != data.maiD_ORDER_ID
      );
      this.orderList = this.orderList.map((a: { maiD_ORDER_ID: any }) => {
        if (a.maiD_ORDER_ID == data.maiD_ORDER_ID) {
          return { ...a, isChecked: false };
        }
        return a;
      });
    }

    this.updatePaginatedOrderList();
    this.isCancelOrderDisabled =
      this.cancelOrderRequest.length > 0 ? false : true;
  }

  private dialog = inject(Dialog);
  private dialogRef!: DialogRef;
  protected openModal(modalToOpen: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(modalToOpen);
  }

  public get fullName(): string {
    let claims = this._authStateService.claims;
    return claims?.name ? claims?.name : '';
  }

  protected closeModal() {
    this.dialogRef?.close();
  }
  cancelOrder() {
    this.isCancelled = true;
    let obj = {
      cancelledBy: this.fullName,
      orders: this.cancelOrderRequest,
    };
    this.thermalPositionService.cancelOrder(obj).subscribe((res) => {
      if (res) {
        const toastRef: ComponentRef<ToastViewerComponent> =
        this.toastService.open(ToastViewerComponent, {
          viewContainerRef: this.viewContainerRef,
        });
        toastRef.instance.Title = 'Success';
        toastRef.instance.Message = "Order is cancelled successfully!";
        toastRef.instance.hasCloseIcon = false;
        toastRef.instance.dismissed.subscribe(() => {
          this.isCancelOrderDisabled = true;
          this.isCancelled = false;
          this.cancelOrderRequest = [];
          this.closeModal();
        });
        this.getOrderList();
      } else {
        this.isCancelOrderDisabled = false;
        this.isCancelled = false;
      }
    });
  }
}
