import { environment } from '../../../environments/environment';

export class BaseHttpApiService {
  protected apiURL: string;
  protected maidConnectorApiURL: string;
  constructor() {
    this.apiURL =
      environment.apiUrl.endsWith('/') == true
        ? environment.apiUrl
        : environment.apiUrl + '/';
    this.maidConnectorApiURL =
      environment.MAIDApiURL.endsWith('/') == true
        ? environment.MAIDApiURL
        : environment.MAIDApiURL + '/';
  }
}
