import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilterRequest } from 'src/app/core/Dtos/filter.entity';
import { LookupResponse } from 'src/app/core/Dtos/lookup.entity';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ThermalPositionService } from 'src/app/core/services/thermal-position.service';
import { ToastViewerComponent } from '../../shared/toast/toast.component';
import { ToastService } from '@engie-group/fluid-design-system-angular';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-thermal-position-order-list',
  templateUrl: './thermal-position-order-list.component.html',
  styleUrl: './thermal-position-order-list.component.scss',
})
export class ThermalPositionOrderListComponent implements OnInit {
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number = 1;
  orderStatusList = [
    { lookupValue: 'Active', bgcolor: 'nj-badge nj-badge--success' },
    { lookupValue: 'Cancelled', bgcolor: 'nj-badge nj-badge--danger' },
    { lookupValue: 'Closed', bgcolor: 'nj-badge nj-badge--danger' },
    { lookupValue: 'Created', bgcolor: 'nj-badge' },
    {
      lookupValue: 'Created Hibernated',
      bgcolor: 'nj-badge nj-badge--information',
    },
    { lookupValue: 'Filled', bgcolor: 'nj-badge nj-badge--success' },
    { lookupValue: 'Hibernating', bgcolor: 'nj-badge nj-badge--information' },
    {
      lookupValue: 'Partially Filled',
      bgcolor: 'nj-badge nj-badge--discovery',
    },
    { lookupValue: 'Pending', bgcolor: 'nj-badge' },
    { lookupValue: 'Rejected', bgcolor: 'nj-badge nj-badge--danger' },
    { lookupValue: 'Unknown', bgcolor: 'nj-badge' },
  ];
  public SearchCurrentAndFuture: string = 'ShowActiveAndFutrure';
  public date: Date = new Date();
  isFilterLoading: boolean = false;
  isCancelled :boolean = false;
  sortBy: string = 'maiD_ORDER_ID';
  reverseSort: boolean = false;
  filterForm!: FormGroup;
  orderList: any[] = [];
  paginatedOrderList: any[] = [];
  selectedStatusString: string = '';
  lookupList: LookupResponse[] = [];
  maidOrderStatusList: LookupResponse[] = [];
  //isSaltendOrIndQueen: boolean = true;

  cancelOrderRequest: any = [];
  isCancelOrderDisabled: boolean = true;
  activeLink : any = '';
  canSubmitOrders: boolean = false;
  constructor(
    private _authStateService: AuthStateService,
    private thermalPositionService: ThermalPositionService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.canSubmitOrders =this._authStateService.checkSubmitMAIDOrders();
    this.activeLink = localStorage.getItem('activeAssetType');


    this.getLookupResponse();
    this.createForm();
    this.getOrderList();
    this.cdr.detectChanges();
  }

  createForm() {
    this.filterForm = this.fb.group({
      orderStatus: new FormControl(''),
      orderId: new FormControl(''),
      //tradeId: new FormControl(""),
      fromDate: new FormControl(this.date.toLocaleDateString('en-CA')),
      toDate: new FormControl(this.date.toLocaleDateString('en-CA')),
    });
  }

  getLookupResponse() {
    this.thermalPositionService.getLookupResponse().subscribe((res) => {
      this.lookupList = res;
      this.maidOrderStatusList = this.lookupList.filter(
        (a) => a.lookupKey === 'MAID_ORDER_STATUS'
      ); //.map(a => ({...a,lookupValue: a.lookupValue.toUpperCase()}))
    });
  }

  onFilter() {
    this.isFilterLoading = true;
    this.getOrderList();
  }

  getOrderList() {
    this.currentPage = 1;
    const selectedValues = this.filterForm.value.orderStatus;
    if (selectedValues && selectedValues.length > 0) {
      this.selectedStatusString = selectedValues.join(',');
    } else {
      this.selectedStatusString = '';
    }

    let obj = new FilterRequest();
    obj.orderStatus = this.selectedStatusString;
    obj.orderId = this.filterForm.value.orderId.trim();
    //obj.tradeId = this.filterForm.value.tradeId;
    obj.fromDate = this.filterForm.value.fromDate;
    obj.toDate = this.filterForm.value.toDate;
    obj.pageSize = this.pageSize;
    obj.currentPage = this.currentPage;
    obj.assetType = this.activeLink.toUpperCase();

    this.thermalPositionService.filterOrderList(obj).subscribe((res) => {
      if (res) {
        this.orderList = res;
        this.paginatedOrderList = res;
        this.totalPages = Math.ceil(this.orderList.length / this.pageSize);
        this.updatePaginatedOrderList();
        this.isFilterLoading = false;
      } else {
        this.isFilterLoading = false;
      }
    });
  }

  onReset() {
    this.filterForm.patchValue({
      orderStatus: [],
      orderId: '',
      fromDate: this.date.toLocaleDateString('en-CA'),
      toDate: this.date.toLocaleDateString('en-CA'),
    });
    this.getOrderList();
  }

  getOrderIcon() {
    return this.reverseSort ? true : false;
  }

  sort(columnName: string) {
    if (this.sortBy === columnName) {
      this.reverseSort = !this.reverseSort;
    } else {
      this.sortBy = columnName;
      this.reverseSort = false;
    }

    this.orderList.sort((a, b) => {
      let aValue = a[columnName];
      let bValue = b[columnName];

      if (columnName === 'createD_DATE') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (aValue < bValue) {
        return this.reverseSort ? 1 : -1;
      } else if (aValue > bValue) {
        return this.reverseSort ? -1 : 1;
      } else {
        return 0;
      }
    });
    this.updatePaginatedOrderList();
  }

  selectedItem(event: any) {
    this.currentPage = event;
    this.updatePaginatedOrderList();
  }

  updatePaginatedOrderList() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedOrderList = this.orderList.slice(start, end);
  }

  public getStatusClass(item: string) {
    let data = this.orderStatusList.filter(
      (a) => a.lookupValue.toLocaleLowerCase() == item.toLocaleLowerCase()
    );
    return data.length > 0 ? data[0].bgcolor : '';
  }

  onOrderSelect(check: any, data: any) {
    if (check) {
      let checkOrder = this.cancelOrderRequest.filter(
        (a: { order_id: any }) => a.order_id == data.maiD_ORDER_ID
      );
      if (checkOrder.length == 0) {
        let obj = { order_id: data.maiD_ORDER_ID };
        this.cancelOrderRequest.push(obj);
      }
      this.orderList = this.orderList.map((a) => {
        if (a.maiD_ORDER_ID == data.maiD_ORDER_ID) {
          return { ...a, isChecked: true };
        }
        return a;
      });
    } else {
      this.cancelOrderRequest = this.cancelOrderRequest.filter(
        (a: { order_id: any }) => a.order_id != data.maiD_ORDER_ID
      );
      this.orderList = this.orderList.map((a) => {
        if (a.maiD_ORDER_ID == data.maiD_ORDER_ID) {
          return { ...a, isChecked: false };
        }
        return a;
      });
    }

    this.updatePaginatedOrderList();
    this.isCancelOrderDisabled =
      this.cancelOrderRequest.length > 0 ? false : true;
  }

  private dialog = inject(Dialog);
  private dialogRef!: DialogRef;
  protected openModal(modalToOpen: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(modalToOpen);
  }

  protected closeModal() {
    this.dialogRef?.close();
  }

  public get fullName(): string {
    let claims = this._authStateService.claims;
    return (
         (claims?.name ? claims?.name : '')
    );
  }

  cancelOrder() {
    this.isCancelled = true;
    let obj ={
      cancelledBy : this.fullName,
      orders : this.cancelOrderRequest
    }
    this.thermalPositionService
      .cancelOrder(obj)
      .subscribe((res) => {
        if (res) {
          const toastRef: ComponentRef<ToastViewerComponent> =
            this.toastService.open(ToastViewerComponent, {
              viewContainerRef: this.viewContainerRef,
            });
          toastRef.instance.Title = 'Success';
          toastRef.instance.Message = ' Order is cancelled successfully!';
          toastRef.instance.hasCloseIcon = false;
          toastRef.instance.dismissed.subscribe(() => {
            this.isCancelOrderDisabled = true;
            this.isCancelled = false;
            this.cancelOrderRequest =[];
            this.closeModal();
          });
          this.getOrderList();
        } else {
          this.isCancelOrderDisabled = false;
          this.isCancelled = false;
        }
      });
  }
}
