<nj-toast
  toastId="toast-1"
  [hasCloseIcon]="hasCloseIcon"
  [shouldDismiss]="true"
  [dismissAfter]="dismissAfter"
  iconName="check"
>
  {{ Title }}
  <ng-container njToastBody>
    {{ Message }}
  </ng-container>
</nj-toast>
