import {
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LookupResponse } from 'src/app/core/Dtos/lookup.entity';
import { ThermalPositionService } from 'src/app/core/services/thermal-position.service';
import { DialogRef } from '@angular/cdk/dialog';
import { format, parse } from 'date-fns';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { ToastService } from '@engie-group/fluid-design-system-angular';
import { ToastViewerComponent } from '../../shared/toast/toast.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-thermal-position-submit-order',
  templateUrl: './thermal-position-submit-order.component.html',
  styleUrl: './thermal-position-submit-order.component.scss',
})
export class ThermalPositionSubmitOrderComponent implements OnInit {
  @Input() item: any;
  orderDetails: any;
  errorList: string[] = [];
  orderForm!: FormGroup;
  lookupList: LookupResponse[] = [];
  accountList: LookupResponse[] = [];
  balanceGroupList: LookupResponse[] = [];
  contactList: LookupResponse[] = [];
  deliveryAreaList: LookupResponse[] = [];
  directionList: LookupResponse[] = [];
  executionStrategyList: LookupResponse[] = [];
  marketList: LookupResponse[] = [];
  productList: LookupResponse[] = [];
  quantityTypeList: LookupResponse[] = [];
  iceBergTemplateList: LookupResponse[] = [];
  volumnTemplateList: LookupResponse[] = [];
  templateList: LookupResponse[] = [];
  manualQuantityTypeList: LookupResponse[] = [];
  customerList: LookupResponse[] = [];
  //isSaltendOrIndQueen: boolean = true;
  isSubmitLoading: boolean = false;
  submitDisabled: boolean = false;
  orderTextList: LookupResponse[] = [];
  orderQuantityTypeList: LookupResponse[] = [];
  filteredContactList: LookupResponse[] = [];
  activeLink: any = '';

  constructor(
    private fb: FormBuilder,
    private _authStateService: AuthStateService,
    private thermalPositionService: ThermalPositionService,
    private toastService: ToastService, private viewContainerRef: ViewContainerRef,
    @Inject(DialogRef) public dialogRef: DialogRef<any>,
    private router: Router
  ) {

  }

  public get userName(): string {
    let claims = this._authStateService.claims;
    return claims?.preferred_username;
  }

  public get fullName(): string {
    let claims = this._authStateService.claims;
    return claims?.name ? claims?.name : '';
  }

  ngOnInit(): void {
    this.activeLink = localStorage.getItem('activeAssetType');
    this.createForm();

    this.orderDetails = this.item.order;
    this.lookupList = this.item.lookupList;
    this.getLookupList();
    this.bindFormValues();
  }

  bindFormValues() {
    let product = this.orderDetails?.isBlock ? 'Block2H' : 'HalfHour';
    let contract =
      this.orderDetails.settlementDate != ''
        ? this.getContractValue(
          this.orderDetails.settlementDate,
          this.orderDetails?.isBlock
        )
        : ''; //this.orderDetails?.isBlock ?  "2H-" + this.orderDetails?.block :  "HH-" + this.orderDetails?.period;
    let qty = 0;

    let orgQty = 0;

    if (this.activeLink === 'saltend') {
      qty = parseFloat(this.orderDetails?.saL_TODO.toFixed(1));
    } else if (this.activeLink === 'indqueens') {
      qty = parseFloat(this.orderDetails?.indQ_TODO.toFixed(1));
    } else if (this.activeLink === 'retail') {
      qty = parseFloat(this.orderDetails?.retaiL_TODO.toFixed(1));
    }
    orgQty = qty;
    qty = qty * (qty < 0 ? -1 : 1);
    qty = qty > 1 ? Math.round(qty) : 0;
    this.orderForm.patchValue(this.orderDetails);
    debugger;
    this.orderForm.patchValue({
      product: product,
      block: this.orderDetails?.hiddenBlock,
      quantity: qty,
      contract: contract,
      direction: orgQty > 0 ? 'Sell' : 'Buy',
    });
    this.contactListFilter(product);
  }

  onChangeProduct(event: any) {
    if (event.value) {
      this.contactListFilter(event.value);
    }
  }

  contactListFilter(product: any) {
    this.filteredContactList = this.contactList.filter(a => a.parentLookupKey == product)
  }



  getContractValue = (settlementDate: string, isBlock: boolean) => {
    var time = settlementDate.split(' ')[1];
    var [hour, minutes, second] = time.split(':');
    var period = minutes === '00' ? 'H1' : 'H2';

    if (!isBlock) {
      return `${hour}${period}`;
    } else {
      var hr = parseInt(hour);
      hr = (hr + 1) % 24;
      var startHour = hr % 2 === 0 ? hr : (hr + 1) % 24;
      var endHour = (startHour + 2) % 24;
      var hourRange = (hour: number) => hour.toString().padStart(2, '0');

      return `${hourRange(startHour)}-${hourRange(endHour)}`;
    }
  };

  onChangeExecutionStrategy(event: any) {
    event.value === 'ManualOrder'
      ? (this.orderQuantityTypeList = this.manualQuantityTypeList)
      : (this.orderQuantityTypeList = this.quantityTypeList);
    if (event.value === 'SmartIceberg') {
      this.templateList = this.iceBergTemplateList;
      this.orderForm.get('orderTemplate')?.enable();
    } else if (event.value === 'SmartVolume') {
      this.templateList = this.volumnTemplateList;
      this.orderForm.get('orderTemplate')?.enable();
    } else {
      this.orderForm.get('orderTemplate')?.disable();
    }
  }

  getLookupList() {
    this.accountList = this.lookupList.filter(
      (a) => a.lookupKey === 'ACCOUNT_EPEX'
    );

    this.balanceGroupList = this.lookupList.filter(
      (a) => a.lookupKey === 'BALANCING_GROUP'
    );

    this.contactList = this.lookupList.filter(
      (a) => a.lookupKey === 'CONTRACT'
    );

    this.deliveryAreaList = this.lookupList.filter(
      (a) => a.lookupKey === 'DELIVERY_AREA'
    );

    this.directionList = this.lookupList.filter(
      (a) => a.lookupKey === 'DIRECTION'
    );

    this.executionStrategyList = this.lookupList.filter(
      (a) => a.lookupKey === 'EXECUTION_STRATEGY'
    );

    this.customerList = this.lookupList.filter(
      (a) => a.lookupKey === 'CUSTOMER'
    );
    this.marketList = this.lookupList.filter((a) => a.lookupKey === 'MARKET');

    this.quantityTypeList = this.lookupList.filter(
      (a) => a.lookupKey === 'QUANTITY_TYPE'
    );
    this.orderQuantityTypeList = this.quantityTypeList;

    this.manualQuantityTypeList = this.lookupList.filter(
      (a) => a.lookupKey === 'QUANTITY_TYPE_MANUAL'
    );

    this.productList = this.lookupList.filter((a) => a.lookupKey === 'PRODUCT');

    this.orderTextList = this.lookupList.filter(
      (a) => a.lookupKey === 'MAID_TEXT'
    );

    this.iceBergTemplateList = this.lookupList.filter(
      (a) => a.lookupKey === 'EXECUTION_STRATEGY_TEMPLATE_SMART_ICE_BERG'
    );
    this.templateList = this.iceBergTemplateList;

    this.volumnTemplateList = this.lookupList.filter(
      (a) => a.lookupKey === 'EXECUTION_STRATEGY_TEMPLATE_SMART_ICE_VOLUME'
    );
  }

  createForm() {

    let balancing_group = '';
    if (this.activeLink === 'saltend' || this.activeLink === 'indqueens') {
      balancing_group = 'SALTEND_P'
    } else if (this.activeLink === 'retail') {
      balancing_group = 'RETAIL_P'
    } else {
      balancing_group = 'SALTEND_P'
    }

    this.orderForm = this.fb.group({
      settlementDate: new FormControl({ value: '', disabled: true }),
      block: new FormControl({ value: '', disabled: true }),
      period: new FormControl({ value: '', disabled: true }),
      executionStrategy: new FormControl('SmartIceberg'),
      market: new FormControl({ value: 'EPEX', disabled: true }),
      quantityType: new FormControl({ value: 'Fixed Side', disabled: false }),
      direction: new FormControl('Buy'),
      contract: new FormControl(''),
      grid: new FormControl('NGET'),
      portfolio: new FormControl(null),
      account: new FormControl('A'),
      customer: new FormControl('SHIFT_UK'),
      balancingGroup: new FormControl(balancing_group),
      quantity: new FormControl(5, Validators.required),
      limit: new FormControl(null, Validators.required),
      product: new FormControl('2H'),
      orderTemplate: new FormControl('Default'),
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  checkDisablitiy() {
    if (this.orderForm.invalid) {
      return true;
    }
    if (this.isSubmitLoading) {
      return true;
    }
    return false;
  }

  formatDate(date: string) {
    let settlementDate = parse(date, 'dd/MM/yyyy HH:mm:ss', new Date());
    let sDate = format(settlementDate, 'yyyy-MM-dd');
    return sDate;
  }

  submitOrder() {
    this.errorList = [];
    if (this.orderForm.valid) {
      let execution_strategy = this.orderForm.get('executionStrategy')?.value;
      if (
        execution_strategy === 'SmartIceberg' ||
        execution_strategy === 'SmartVolume'
      ) {
        execution_strategy =
          execution_strategy + '.' + this.orderForm.get('orderTemplate')?.value;
      }

      this.isSubmitLoading = true;
      let settlementDate = this.orderForm.get('settlementDate')?.value;
      let obj = {
        external_id: this.userName,
        fullName: this.fullName,
        direction: this.orderForm.get('direction')?.value,
        quantity: this.orderForm.value.quantity,
        customer:  this.orderForm.get('customer')?.value,
        limit: this.orderForm.value.limit,
        unit: 'EUR/MWh',
        delivery_on: settlementDate
          ? this.formatDate(settlementDate)
          : settlementDate,
        contract: this.orderForm.get('contract')?.value,
        product: this.orderForm.get('product')?.value,
        execution_strategy: execution_strategy,
        grid: this.orderForm.get('grid')?.value,
        balancing_group: this.orderForm.get('balancingGroup')?.value,
        settlementDate: parse(
          settlementDate,
          'dd/MM/yyyy HH:mm:ss',
          new Date()
        ),
        block: this.orderForm.get('block')?.value,
        period: this.orderForm.get('period')?.value,
        asset_type: this.activeLink.toLocaleUpperCase(),
      };
      let order = {
        orders: [obj],
      };

      console.log(obj);

      this.thermalPositionService
        .submitOrderRequest(order)
        .subscribe((res: any) => {
          var response = JSON.parse(res.response);
          if (res.success) {
            let orderData = response[0];

            const toastRef: ComponentRef<ToastViewerComponent> =
              this.toastService.open(ToastViewerComponent, {
                viewContainerRef: this.viewContainerRef,
              });
            toastRef.instance.Title = 'Success';
            toastRef.instance.Message = orderData.id + ' \n Order is created successfully!';
            toastRef.instance.hasCloseIcon = false;
            toastRef.instance.dismissed.subscribe(() => {
              this.isSubmitLoading = false;
              this.closeModal();
            });
          } else {
            this.isSubmitLoading = false;
            if (Array.isArray(response.detail)) {
              response.detail.forEach((element: any) => {
                this.errorList.push(element.msg);
              });
            } else {
              this.errorList.push(response.detail);
            }
          }
        });
    }
  }
}
