import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastComponent } from '@engie-group/fluid-design-system-angular';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastViewerComponent {
  @ViewChild(ToastComponent, { read: ToastComponent }) toast:
    | ToastComponent
    | undefined;

  @Input()
  Title: string = '';

  @Input()
  Message: string = '';

  @Input()
  hasCloseIcon: boolean = true;

  @Input() dismissAfter: number = 2500;
  @Output() dismissed = new EventEmitter<void>();

  ngAfterViewInit(): void {
    if (this.toast) {
      this.toast.hasCloseIcon = this.hasCloseIcon;
      this.toast.dismissAfter = this.dismissAfter;
      this.toast.dismissed.subscribe(() => {
        this.dismissed.emit();
      });
    }
  }
}
