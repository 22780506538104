import { Component, ViewContainerRef } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ThermalPositionService } from './core/services/thermal-position.service';
import { ToastService } from '@engie-group/fluid-design-system-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'POS-Tool';
  connection!: signalR.HubConnection;
  constructor(
    private thermalPositionService: ThermalPositionService,
    private toastService: ToastService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    setInterval(() => {
      this.thermalPositionService.refreshOrder().subscribe((res) => {});
    }, 60000);
  }
}
