<form
  [formGroup]="orderForm"
  style="display: flex; flex-direction: column; gap: 20px"
>
  <div class="row">
    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <input
            type="text"
            class="nj-form-item__field"
            id="settlementDate"
            formControlName="settlementDate"
          />
          <label for="settlementDate" class="nj-form-item__label"
            >DateTime</label
          >
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <input
            type="text"
            class="nj-form-item__field"
            id="block"
            formControlName="block"
          />
          <label for="block" class="nj-form-item__label">Block</label>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <input
            type="text"
            class="nj-form-item__field"
            id="period"
            placeholder="default"
            formControlName="period"
          />
          <label for="period" class="nj-form-item__label">Period</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div
        class="nj-form-item nj-form-item--sm nj-form-item--sm nj-form-item--select"
      >
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            (change)="onChangeExecutionStrategy($event.target)"
            id="executionStrategy"
            formControlName="executionStrategy"
            required
          >
            <option
              *ngFor="let data of executionStrategyList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Execution Strategy<span style="color: red">*</span></label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="orderTemplate"
            formControlName="orderTemplate"
          >
            <option
              *ngFor="let data of templateList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="orderTemplate" class="nj-form-item__label"
            >Template</label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div
        class="nj-form-item nj-form-item--sm nj-form-item--sm nj-form-item--select"
      >
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="market"
            required
            formControlName="market"
          >
            <option *ngFor="let data of marketList" [value]="data.lookupValue">
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label">Market</label>
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="market"
            required
            formControlName="quantityType"
          >
            <option
              *ngFor="let data of orderQuantityTypeList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Quantity Type</label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="direction"
            formControlName="direction"
            required
          >
            <option
              *ngFor="let data of directionList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Buy Or Sell<span style="color: red">*</span></label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="product"
            (change)="onChangeProduct($event.target)"
            formControlName="product"
          >
            <option *ngFor="let data of productList" [value]="data.lookupValue">
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="product" class="nj-form-item__label">Product</label>
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="contract"
            formControlName="contract"
            required
          >
            <option *ngFor="let data of filteredContactList" [value]="data.lookupValue">
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="contract" class="nj-form-item__label"
            >Contract<span style="color: red">*</span></label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <input
            type="number"
            class="nj-form-item__field"
            id="quantity"
            placeholder="default"
            formControlName="quantity"
            required
          />
          <label for="quantity" class="nj-form-item__label"
            >Quantity <span style="color: red">*</span></label
          >
        </div>
        <div
          style="color: red"
          *ngIf="
            orderForm.get('quantity')?.invalid &&
            orderForm.get('quantity')?.touched
          "
        >
          <small class="text-danger">Quantity is required</small>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <input
            type="number"
            class="nj-form-item__field"
            id="limit"
            placeholder="default"
            formControlName="limit"
            required
          />
          <label for="exampleSelect2" class="nj-form-item__label"
            >Limit <span style="color: red">*</span></label
          >
        </div>
        <div
          style="color: red"
          *ngIf="
            orderForm.get('limit')?.invalid && orderForm.get('limit')?.touched
          "
        >
          <small class="text-danger">Limit is required</small>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="grid"
            required
            formControlName="grid"
          >
            <option
              *ngFor="let data of deliveryAreaList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Delivery Area<span style="color: red">*</span></label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <select class="nj-form-item__field" id="customer" formControlName="customer">
            <option
              *ngFor="let data of customerList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <!-- <select class="nj-form-item__field" id="text" formControlName="text">
            <option></option>
            <option>option 2</option>
            <option>option 3</option>
          </select> -->
          <label for="exampleSelect2" class="nj-form-item__label">Customer</label>
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <!-- <div class="nj-form-item nj-form-item--sm nj-form-item--select">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            formControlName="portfolio"
            id="portfolio"
          >
            <option></option>
            <option>option 2</option>
            <option>option 3</option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Portfolio</label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div> -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="account"
            formControlName="account"
            required
          >
            <option *ngFor="let data of accountList" [value]="data.lookupValue">
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Account (EPEX)<span style="color: red">*</span></label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nj-form-item nj-form-item--sm">
        <div class="nj-form-item__field-wrapper">
          <select
            class="nj-form-item__field"
            id="balancingGroup"
            formControlName="balancingGroup"
          >
            <option
              *ngFor="let data of balanceGroupList"
              [value]="data.lookupValue"
            >
              {{ data.lookupValue }}
            </option>
          </select>
          <label for="exampleSelect2" class="nj-form-item__label"
            >Balancing Group<span style="color: red">*</span></label
          >
          <span aria-hidden="true" class="nj-form-item__icon material-icons"
            >keyboard_arrow_down</span
          >
        </div>
      </div>
    </div>
  </div>
</form>
<div
  *ngIf="errorList.length > 0"
  style="
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    flex-wrap: wrap;
    gap: 24px;
  "
>
  <div class="nj-inline-message">
    <div
      class="nj-inline-message__status nj-status-indicator nj-status-indicator--error"
      aria-hidden="true"
    >
      <div class="nj-status-indicator__svg"></div>
    </div>
    <div class="nj-inline-message__content">
      <h4 class="nj-inline-message__title">
        Submit Order <b>error</b> Message
      </h4>
      @for (item of errorList; track item) {
      <p class="nj-inline-message__body">
        {{ item }}
      </p>
      }
    </div>
  </div>
</div>
<div class="btn-grp">
  <button class="subtle" (click)="closeModal()">Cancel</button>
  <button class="nj-btn" *ngIf="canSubmitOrders" [disabled]="checkDisablitiy()" (click)="submitOrder()">
    Submit
    <span
      aria-live="polite"
      aria-atomic="true"
      [class.nj-spinner]="isSubmitLoading"
      [class.nj-spinner--inverse]="isSubmitLoading"
      [class.nj-spinner--xs]="isSubmitLoading"
    ></span>
  </button>
</div>
