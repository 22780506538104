import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DoCheck,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ThermalPositionService } from 'src/app/core/services/thermal-position.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as signalR from '@microsoft/signalr';
import { format, parse } from 'date-fns';
import { ToastViewerComponent } from '../../shared/toast/toast.component';
import { ToastService } from '@engie-group/fluid-design-system-angular';
import { Clipboard } from '@angular/cdk/clipboard';
import { NavigationEnd, Router } from '@angular/router';
import { AuthStateService } from 'src/app/core/services/auth-state.service';

@Component({
  selector: 'app-thermal-position-dashboard',
  templateUrl: './thermal-position-dashboard.component.html',
  styleUrl: './thermal-position-dashboard.component.scss',
})
export class ThermalPositionDashboardComponent implements OnInit {
  connection!: signalR.HubConnection;
  columnForm!: FormGroup;
  public SearchDateFrom: Date = new Date();
  public SearchDateTo: Date = new Date();
  dashboardList: any;
  previousBlock = '';
  previousClass = 'lightyellow';
  orderObj: any = [];
  isSaltendOrIndQueen: boolean = true;
  buySellLabel = 'BUY';
  appConfigData: any;
  appConfigObj: any = [];
  melselList: any = [];
  dutyAllocationList: any = [];
  lookupList: any = [];
  orderRequest: any;
  selectedColumns: any;
  lastSyncDateTime: any;
  lastSyncMELSELDateTime: any;
  unitColor = '#F6FB7A';
  orderLabel = 'HalfHour';

  copiedValue: number | null = null;
  selectedRow: any = null;
  selectedField: string | null = null;
  private clickTimeout: any = null;
  private readonly DOUBLE_CLICK_DELAY = 300;

  badgeClass: any = [
    'nj-badge nj-badge--danger',
    'nj-badge nj-badge--success',
    'nj-badge nj-badge--warning',
    'nj-badge nj-badge--primary',
  ];
  assetTypeList = ['SALTEND', 'INDQUEENS', 'RETAIL', 'HYDRO'];
  isUpdateLoading: boolean = false;
  noDataFound: string = '';
  private dialog = inject(Dialog);
  public dialogRef!: DialogRef;
  posMasterReadonlyUsersGroup: boolean = true;
  visibleColumns: any = {
    saltend: {
      U1: true,
      U2: true,
      U3: true,
      U4: true,
      REQUIRED: true,
      CONTRACTED: true,
      TODO: true,
      SUBMITTED: true,
      PENDING: true,
    },
    india_Queen: {
      U1: true,
      CONTRACTED: true,
      TODO: true,
      SUBMITTED: true,
      PENDING: true,
    },
    hydro: {
      DINO_1: true,
      DINO_2: true,
      DINO_3: true,
      DINO_4: true,
      DINO_5: true,
      DINO_6: true,
      FFES_1: true,
      FFES_2: true,
      FFES_3: true,
      FFES_4: true,
      REQUIRED: true,
      CONTRACTED: true,
      LOSSES: true,
      REQ_GEN: true,
      TODO: true,
      SUBMITTED: true,
      PENDING: true,
    },
  };
  //allFalse: boolean = true;
  saltend_positionsColumnsCount: number = 4;
  saltend_unitsColumnCount: number = 5;

  hydro_positionsColumnsCount: number = 7;
  hydro_unitsColumnCount: number = 10;

  indQueen_positionsColumnsCount: number = 4;
  indQueen_unitsColumnCount: number = 1;
  activeLink: any = '';
  canSubmitOrders: boolean = false;
  constructor(
    private thermalPositionService: ThermalPositionService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private viewContainerRef: ViewContainerRef,
    private clipboard: Clipboard,
    private router: Router,
    private _authStateService: AuthStateService
  ) {
    this.getAppConfigData();
  }

  ngOnInit(): void {
    this.canSubmitOrders = this._authStateService.checkSubmitMAIDOrders();
    this.activeLink = localStorage.getItem('activeAssetType');
    this.getUnitsDutyAllocations_SPAD();
    this.createForm();
    this.getLookupList();
    this.callSignalr();
    this.checkDashboardList(true);
    this.columnForm
      .get('columns')
      ?.valueChanges.subscribe((selectedValues: any) => {
        this.selectedColumns = selectedValues.map((selectedValue: any) => {
          const element = document.querySelector(
            `li[data-value="${selectedValue}"]`
          );
          return {
            value: selectedValue,
            category: element?.getAttribute('data-category'),
          };
        });
        this.onColumnChange();
      });
    this.cdr.detectChanges();
  }


  onColumnChange() {
    this.saltend_unitsColumnCount = 5;
    this.saltend_positionsColumnsCount = 4;

    this.indQueen_unitsColumnCount = 1;
    this.indQueen_positionsColumnsCount = 4;

    this.hydro_positionsColumnsCount = 5;
    this.hydro_unitsColumnCount = 10;

    this.visibleColumns = {
      saltend: {
        U1: true,
        U2: true,
        U3: true,
        U4: true,
        REQUIRED: true,
        CONTRACTED: true,
        TODO: true,
        SUBMITTED: true,
        PENDING: true,
      },
      india_Queen: {
        U1: true,
        CONTRACTED: true,
        TODO: true,
        SUBMITTED: true,
        PENDING: true,
      },
      hydro: {
        DINO_1: true,
        DINO_2: true,
        DINO_3: true,
        DINO_4: true,
        DINO_5: true,
        DINO_6: true,
        FFES_1: true,
        FFES_2: true,
        FFES_3: true,
        FFES_4: true,
        REQUIRED: true,
        CONTRACTED: true,
        LOSSES: true,
        REQ_GEN: true,
        TODO: true,
        SUBMITTED: true,
        PENDING: true,
      },
    };

    if (this.activeLink === 'saltend') {
      this.selectedColumns.forEach((column: any) => {
        if (this.visibleColumns.saltend.hasOwnProperty(column.value)) {
          this.visibleColumns.saltend[column.value] = false;

          if (column.category == 'unit' && this.saltend_unitsColumnCount != 0) {
            this.saltend_unitsColumnCount = this.saltend_unitsColumnCount - 1;
          }
          if (
            column.category == 'position' &&
            this.saltend_positionsColumnsCount != 0
          ) {
            this.saltend_positionsColumnsCount =
              this.saltend_positionsColumnsCount - 1;
          }
        }
      });
    } else if (this.activeLink === 'ind-queen') {
      this.selectedColumns.forEach((column: any) => {
        if (this.visibleColumns.india_Queen.hasOwnProperty(column.value)) {
          this.visibleColumns.india_Queen[column.value] = false;

          if (
            column.category == 'unit' &&
            this.indQueen_unitsColumnCount != 0
          ) {
            this.indQueen_unitsColumnCount = this.indQueen_unitsColumnCount - 1;
          }
          if (
            column.category == 'position' &&
            this.indQueen_positionsColumnsCount != 0
          ) {
            this.indQueen_positionsColumnsCount =
              this.indQueen_positionsColumnsCount - 1;
          }
        }
      });
    } else if (this.activeLink === 'hydro') {
      this.selectedColumns.forEach((column: any) => {
        if (this.visibleColumns.hydro.hasOwnProperty(column.value)) {
          this.visibleColumns.hydro[column.value] = false;

          if (column.category == 'unit' && this.hydro_unitsColumnCount != 0) {
            this.hydro_unitsColumnCount = this.hydro_unitsColumnCount - 1;
          }
          if (
            column.category == 'position' &&
            this.hydro_positionsColumnsCount != 0
          ) {
            this.hydro_positionsColumnsCount =
              this.hydro_positionsColumnsCount - 1;
          }
        }
      });
    }
  }

  callSignalr() {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this.thermalPositionService.getSignalrHubURL())
      .build();
    this.connection
      .start()
      .then(() => {
        console.log('SignalR Connected!');
      })
      .catch(function (err: { toString: () => any }) {
        return console.error(err.toString());
      });

    this.connection.on('NewUserConnected', (message: any) => {
      // console.log(message);
    });

    this.connection.on('MaidOrderList', (orders: any[]) => {
      console.log('MaidOrderList Response : ', orders);
      if (orders) {
        this.updateValues(orders);
        this.cdr.detectChanges();
        if (this.activeLink === 'hydro') {
          localStorage.setItem(
            'hydroDashboardList',
            JSON.stringify(this.dashboardList)
          );
        } else {
          localStorage.setItem(
            'dashboardList',
            JSON.stringify(this.dashboardList)
          );
        }
      }
    });
  }

  updateValues(orders: any) {
    orders.forEach(
      (data: {
        settlementDate: string | number | Date;
        period: any;
        block: any;
        assetType: string;
        submittedQty: number;
        pendingQty: number;
      }) => {
        //let date = format(new Date(data.settlementDate), 'dd-MM-yyyy'); //new Date(data.settlementDate).toISOString().split('T')[0];
        let index = this.dashboardList.findIndex(
          (a: {
            hiddenBlock: any;
            period: any;
            timeFrom: any;
            assetType: any;
          }) =>
            a.hiddenBlock == ''
              ? a.period == data.period && a.timeFrom == data.settlementDate
              : a.hiddenBlock == data.block &&
                a.period == data.period &&
                a.timeFrom == data.settlementDate
        );
        this.cdr.detectChanges();
        if (index != -1 && data.assetType == this.assetTypeList[0]) {
          this.dashboardList[index].saL_Submitted = Math.round(
            data.submittedQty
          );
          this.dashboardList[index].pendingQty = Math.round(data.pendingQty);
          this.dashboardList[index].sal_submitted_badge =
            data.pendingQty == 0
              ? this.badgeClass[1]
              : data.pendingQty > 0
              ? this.badgeClass[2]
              : this.badgeClass[2];
          this.cdr.detectChanges();
        } else if (index != -1 && data.assetType == this.assetTypeList[1]) {
          this.dashboardList[index].indQ_Submitted = Math.round(
            data.submittedQty
          );
          this.dashboardList[index].indQ_PendingQty = Math.round(
            data.pendingQty
          );
          this.dashboardList[index].indQ_submitted_badge =
            data.pendingQty == 0
              ? this.badgeClass[1]
              : data.pendingQty > 0
              ? this.badgeClass[2]
              : this.badgeClass[2];
          this.cdr.detectChanges();
        } else if (index != -1 && data.assetType == this.assetTypeList[2]) {
          this.dashboardList[index].retaiL_Submitted = Math.round(
            data.submittedQty
          );
          this.dashboardList[index].retaiL_PendingQty = Math.round(
            data.pendingQty
          );
          this.dashboardList[index].retaiL_submitted_badge =
            data.pendingQty == 0
              ? this.badgeClass[1]
              : data.pendingQty > 0
              ? this.badgeClass[2]
              : this.badgeClass[2];
          this.cdr.detectChanges();
        } else if (index != -1 && data.assetType == this.assetTypeList[3]) {
          this.dashboardList[index].hydrO_Submitted = Math.round(
            data.submittedQty
          );
          this.dashboardList[index].hydrO_PendingQty = Math.round(
            data.pendingQty
          );
          this.dashboardList[index].hydrO_Submitted_badge =
            data.pendingQty == 0
              ? this.badgeClass[1]
              : data.pendingQty > 0
              ? this.badgeClass[2]
              : this.badgeClass[2];
          this.cdr.detectChanges();
        }
      }
    );
  }

  formatDate(date: string) {
    let settlementDate = parse(date, 'dd/MM/yyyy HH:mm:ss', new Date());
    let sDate = format(settlementDate, 'dd-MM-yyyy');
    return sDate;
  }

  convertValue(value: number) {
    if (value != 0) {
      return Math.round(value);
    }
    return value;
  }

  createForm() {
    this.columnForm = this.fb.group({
      columns: new FormControl(''),
    });
  }

  getLookupList() {
    this.thermalPositionService.getLookupResponse().subscribe((res) => {
      this.lookupList = res;
      this.orderObj = {
        lookupList: this.lookupList,
      };
    });
  }

  getMELSEL() {
    this.thermalPositionService.getMELSEL().subscribe((res) => {
      this.melselList = res;
      this.lastSyncMELSELDateTime = Date();
      if (this.activeLink === 'saltend') {
        this.melselList = this.melselList.filter(
          (a: { bmuName: string | string[] }) => a.bmuName != 'INDQ-1'
        );
      } else if (this.activeLink === 'indqueens') {
        this.melselList = this.melselList.filter(
          (a: { bmuName: string | string[] }) => a.bmuName === 'INDQ-1'
        );
      }

      if (this.melselList.length === 0) {
        this.noDataFound = 'No data found!';
      }
    });
  }

  displayDutyAllocation(bmuName: string) {
    var dutyList = this.dutyAllocationList.filter(
      (a: { bmuName: string | string[] }) => a.bmuName === bmuName
    );
    //console.log(this.dutyAllocationList);
    return dutyList.length > 0 ? dutyList[0].bmuDuty : '';
  }

  getUnitsDutyAllocations_SPAD() {
    this.thermalPositionService
      .getUnitsDutyAllocations_SPAD()
      .subscribe((res) => {
        this.dutyAllocationList = res;
      });
  }

  getAppConfigData() {
    this.thermalPositionService.getAppConfigData().subscribe((res) => {
      this.appConfigData = res;
      if (this.appConfigData) {
        this.appConfigObj = this.appConfigData.reduce(
          (
            acc: { [x: string]: any },
            item: { configName: string; configValue: any }
          ) => {
            acc[item.configName.toLowerCase()] = item.configValue;
            return acc;
          },
          {}
        );
        this.cdr.detectChanges();
      }
    });
  }

  getRowClass(item: any): string {
    if (item.block) {
      return 'lightblue';
    } else {
      return '';
    }
  }

  checkDashboardList(isDefaultLoad: boolean) {
    if (isDefaultLoad) {
      const storedDashboardList =
        this.activeLink === 'hydro'
          ? localStorage.getItem('hydroDashboardList')
          : localStorage.getItem('dashboardList');
      const lastSyncDateTime =
        this.activeLink === 'hydro'
          ? localStorage.getItem('hydroLastSyncDateTime')
          : localStorage.getItem('lastSyncDateTime');

      if (lastSyncDateTime) {
        let lastSyncTime = new Date(lastSyncDateTime).getTime();
        let currentTime = new Date().getTime();
        let diff = currentTime - lastSyncTime;

        if (diff >= 600000) {
          this.activeLink === 'hydro'
            ? localStorage.removeItem('hydroDashboardList')
            : localStorage.removeItem('dashboardList'); //remove after 10 min

          this.activeLink === 'hydro'
            ? localStorage.removeItem('hydroLastSyncDateTime')
            : localStorage.removeItem('lastSyncDateTime'); //remove after 10 min

          this.activeLink === 'hydro'
            ? this.getHydroDashboardList()
            : this.getDashboardList();
        } else {
          if (storedDashboardList) {
            this.dashboardList = JSON.parse(storedDashboardList);
            this.isUpdateLoading = false;

            this.lastSyncDateTime = lastSyncDateTime
              ? lastSyncDateTime
              : Date();
          } else {
            this.activeLink === 'hydro'
              ? this.getHydroDashboardList()
              : this.getDashboardList();
          }
        }
      } else {
        this.activeLink === 'hydro'
          ? this.getHydroDashboardList()
          : this.getDashboardList();
      }
      // if (storedDashboardList) {
      //   this.dashboardList = JSON.parse(storedDashboardList);
      //   this.isUpdateLoading = false;

      //   this.lastSyncDateTime = lastSyncDateTime ? lastSyncDateTime : Date();
      // } else {
      //   this.getDashboardList();
      // }
    } else {
      this.activeLink === 'hydro'
        ? this.getHydroDashboardList()
        : this.getDashboardList();
    }
  }

  getHydroDashboardList() {
    this.thermalPositionService.getHydroDashboardList().subscribe((res) => {
      if (res) {
        this.dashboardList = res;
        this.lastSyncDateTime = Date();
        localStorage.setItem('hydroLastSyncDateTime', this.lastSyncDateTime);
        this.dashboardList?.forEach(
          (data: {
            hydrO_TODO: number;
            hydrO_IMBAL_TODO: number;
            hydrO_SID_NCP_CONTRACTED: number;

            hydrO_PNS_REQUIRED: number;
            hydrO_Submitted: number;
            hydro_TODO_Display: number;
            hydrO_ExecutedQty: number;
            hydrO_PendingQty: number;
            hydrO_submitted_badge: any;
          }) => {
            if (data.hydrO_PendingQty == 0 && data.hydrO_Submitted != 0) {
              data.hydrO_submitted_badge = this.badgeClass[1];
            } else if (data.hydrO_PendingQty > 0 && data.hydrO_Submitted != 0) {
              data.hydrO_submitted_badge = this.badgeClass[2];
            } else {
              data.hydrO_submitted_badge = '';
            }
            this.cdr.detectChanges();
          }
        );

        this.isUpdateLoading = false;
        localStorage.setItem(
          'hydroDashboardList',
          JSON.stringify(this.dashboardList)
        );
      } else {
        this.isUpdateLoading = false;
      }
    });
  }

  getDashboardList() {
    this.thermalPositionService.getDashboardList().subscribe((res) => {
      if (res) {
        this.dashboardList = res;
        this.lastSyncDateTime = Date();
        localStorage.setItem('lastSyncDateTime', this.lastSyncDateTime);
        this.dashboardList?.forEach(
          (data: {
            saL_Submitted: number;
            indQ_Submitted: number;
            sal_submitted_badge: any;
            indQ_PendingQty: number;
            pendingQty: number;
            indQ_submitted_badge: any;
            retaiL_Submitted: number;
            retaiL_submitted_badge: any;
            retaiL_PendingQty: number;
          }) => {
            if (this.activeLink === 'saltend') {
              if (data.pendingQty == 0 && data.saL_Submitted != 0) {
                data.sal_submitted_badge = this.badgeClass[1];
              } else if (data.pendingQty > 0 && data.saL_Submitted != 0) {
                data.sal_submitted_badge = this.badgeClass[2];
              } else {
                data.sal_submitted_badge = '';
              }
            } else if (this.activeLink === 'indqueens') {
              if (data.indQ_PendingQty == 0 && data.indQ_Submitted != 0) {
                data.indQ_submitted_badge = this.badgeClass[1];
              } else if (data.indQ_PendingQty > 0 && data.indQ_Submitted != 0) {
                data.indQ_submitted_badge = this.badgeClass[2];
              } else {
                data.indQ_submitted_badge = '';
              }
            } else if (this.activeLink === 'retail') {
              if (data.retaiL_PendingQty == 0 && data.retaiL_Submitted != 0) {
                data.retaiL_submitted_badge = this.badgeClass[1];
              } else if (
                data.retaiL_PendingQty > 0 &&
                data.retaiL_Submitted != 0
              ) {
                data.retaiL_submitted_badge = this.badgeClass[2];
              } else {
                data.retaiL_submitted_badge = '';
              }
            }
            this.cdr.detectChanges();
          }
        );

        this.isUpdateLoading = false;
        localStorage.setItem(
          'dashboardList',
          JSON.stringify(this.dashboardList)
        );
      } else {
        this.isUpdateLoading = false;
      }
    });
  }

  updateCellColor(item: any, fieldName: any) {
    if (fieldName === 'saL_U1') {
      item['sal_u1_bgColor'] = this.unitColor;
    } else if (fieldName === 'saL_U2') {
      item['sal_u2_bgColor'] = this.unitColor;
    } else if (fieldName === 'saL_U3') {
      item['sal_u3_bgColor'] = this.unitColor;
    } else if (fieldName === 'saL_U4') {
      item['sal_u4_bgColor'] = this.unitColor;
    } else if (fieldName === 'indQ_U1') {
      item['Ind_u1_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_DINO1') {
      item['hydrO_DINO1_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_DINO2') {
      item['hydrO_DINO2_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_DINO3') {
      item['hydrO_DINO3_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_DINO4') {
      item['hydrO_DINO4_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_DINO5') {
      item['hydrO_DINO5_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_DINO6') {
      item['hydrO_DINO6_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_FFES1') {
      item['hydrO_FFES1_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_FFES2') {
      item['hydrO_FFES2_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_FFES3') {
      item['hydrO_FFES3_bgColor'] = this.unitColor;
    } else if (fieldName === 'hydrO_FFES4') {
      item['hydrO_FFES4_bgColor'] = this.unitColor;
    }
  }

  onCellClick(event: any, item: any) {
    const fieldName = event.target.getAttribute('data-field');
    this.selectedRow = item;
    this.selectedField = fieldName;

    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;

      // Handle double click
      if (
        (fieldName === 'saL_U1' ||
          fieldName === 'saL_U2' ||
          fieldName === 'saL_U3' ||
          fieldName === 'saL_U4' ||
          fieldName === 'indQ_U1') &&
        this.copiedValue !== null
      ) {
        this.pasteValue(item, fieldName);
        this.updateCellColor(item, fieldName);
      }
    } else {
      // Handle single click
      this.clickTimeout = setTimeout(() => {
        this.clickTimeout = null;
      }, this.DOUBLE_CLICK_DELAY);
    }
  }

  onKeydown(event: any, item: any) {
    const fieldName = event.target.getAttribute('data-field');
    if (event.ctrlKey && event.key === 'c') {
      this.copyValue(item, fieldName);
    } else if (event.ctrlKey && event.key === 'v') {
      this.pasteValue(item, fieldName);
    }
  }

  copyValue(item: any, field: string) {
    this.copiedValue = item[field];
    if (this.copiedValue !== null) {
      this.clipboard.copy(this.copiedValue.toString());
    }
  }

  pasteValue(item: any, field: string) {
    if (this.copiedValue !== null && this.selectedRow && this.selectedField) {
      this.selectedRow[this.selectedField] = this.copiedValue;
      this.dashboardList = [...this.dashboardList];
    }
  }

  onKeyUp(event: any, item: any) {
    const value = parseFloat(event.target.value);
    const fieldName = event.target.getAttribute('data-field');
    if (value != item[fieldName]) {
      this.updateCellColor(item, fieldName);
    }
    item[fieldName] = isNaN(value) ? 0 : value;
  }

  getSalCount(item: any) {
    item.saL_Cont_Display = Math.round(parseFloat(item.saL_Cont) | 0);
    return item.saL_Cont_Display * (item.saL_Cont_Display < 0 ? -1 : 1);
  }

  getHydroCount(item: any) {
    item.hydrO_SID_NCP_CONTRACTED_Display = item.hydrO_SID_NCP_CONTRACTED;
    return (
      item.hydrO_SID_NCP_CONTRACTED_Display *
      (item.hydrO_SID_NCP_CONTRACTED_Display < 0 ? -1 : 1)
    );
  }

  getLossesDisplay(item: any) {
    return (
      // item.hydrO_Losses_Display = Math.round(
      //   parseFloat(item.hydrO_Losses) | 0
      // )
      item.hydrO_Losses
    );
  }

  getReqGenDisplay(item: any) {
    return (
      // item.hydrO_Req_Gen_Display = Math.round(
      //   parseFloat(item.hydrO_Req_Gen) | 0
      // )
      item.hydrO_Req_Gen
    );
  }

  calculateREQD(item: any) {
    item.saL_Reqd =
      (parseFloat(item.saL_U1) +
        parseFloat(item.saL_U2) +
        parseFloat(item.saL_U3) +
        parseFloat(item.saL_U4)) *
      this.appConfigObj.erg_tlm;
    item.saL_Reqd = parseFloat(item.saL_Reqd.toFixed(2));
    item.saL_Reqd_Display = Math.round(parseFloat(item.saL_Reqd));
    return item.saL_Reqd_Display * (item.saL_Reqd_Display < 0 ? -1 : 1);
  }

  calculateREQDHydro(item: any) {
    item.hydrO_PNS_REQUIRED =
      parseFloat(item.hydrO_DINO1) +
      parseFloat(item.hydrO_DINO2) +
      parseFloat(item.hydrO_DINO3) +
      parseFloat(item.hydrO_DINO4) +
      parseFloat(item.hydrO_DINO5) +
      parseFloat(item.hydrO_DINO6) +
      parseFloat(item.hydrO_FFES1) +
      parseFloat(item.hydrO_FFES2) +
      parseFloat(item.hydrO_FFES3) +
      parseFloat(item.hydrO_FFES4);
    item.hydrO_PNS_REQUIRED = parseFloat(item.hydrO_PNS_REQUIRED.toFixed(2));
    item.hydrO_PNS_REQUIRED_Display = Math.round(
      parseFloat(item.hydrO_PNS_REQUIRED)
    );
    return (
      item.hydrO_PNS_REQUIRED * (item.hydrO_PNS_REQUIRED_Display < 0 ? -1 : 1)
    );
  }

  calculateTODO(item: any) {
    let x = 0;
    if (item.saL_U1 == 0) x++;
    if (item.saL_U2 == 0) x++;
    if (item.saL_U3 == 0) x++;

    x =
      x == 1
        ? parseFloat(this.appConfigObj.saltend_two_units_on)
        : x == 2 || x == 3
        ? parseFloat(this.appConfigObj.saltend_no_units_on)
        : x;
    item.saL_TODO = item.saL_Reqd - item.saL_Cont - x;

    if (item.saL_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.saL_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    item.saL_TODO = parseFloat(item.saL_TODO.toFixed(2));
    item.saL_TODO_Display =
      item.saL_TODO > -1 && item.saL_TODO < 1 ? 0 : Math.round(item.saL_TODO);
    item.bgColor =
      item.saL_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.saL_TODO_Display; //* (item.saL_TODO_Display < 0 ? -1 : 1);
  }

  calculateHydroTODO(item: any) {
    item.hydrO_TODO = item.hydrO_PNS_REQUIRED - item.hydrO_Req_Gen;

    if (item.hydrO_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.hydrO_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    // item.hydrO_TODO = parseFloat(item.hydrO_TODO.toFixed(2));
    // item.hydrO_TODO_Display =
    //   item.hydrO_TODO > -1 && item.hydrO_TODO < 1 ? 0 : Math.round(item.hydrO_TODO);
    item.bgColor =
      item.hydrO_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.hydrO_TODO; //* (item.saL_TODO_Display < 0 ? -1 : 1);
  }

  calculateINDQUETODO(item: any) {
    const indQ_U1 = item.indQ_U1 ? parseFloat(item.indQ_U1) : 0;
    item.indQ_TODO =
      indQ_U1 * this.appConfigObj.indquen_iq_transmission -
      (item.indQ_Cont ? parseFloat(item.indQ_Cont) : 0);

    if (item.indQ_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.indQ_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    item.indQ_TODO_Display =
      item.indQ_TODO > -1 && item.indQ_TODO < 1
        ? 0
        : Math.round(parseFloat(item.indQ_TODO));
    item.bgColor =
      item.indQ_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.indQ_TODO_Display; // * (item.indQ_TODO_Display < 0 ? -1 : 1);
  }

  calculateRETAILTODO(item: any) {
    if (item.retaiL_TODO < 0) {
      item.bgColor = this.badgeClass[0];
    } else if (item.retaiL_TODO > 0) {
      item.bgColor = this.badgeClass[1];
    } else {
      item.bgColor = this.badgeClass[3];
    }

    item.retail_TODO_Display =
      item.retaiL_TODO > -1 && item.retaiL_TODO < 1
        ? 0
        : Math.round(parseFloat(item.retaiL_TODO));
    item.bgColor =
      item.retail_TODO_Display == 0 ? this.badgeClass[3] : item.bgColor;
    return item.retail_TODO_Display;
  }

  onIndQueenKeyUp(event: any, item: any) {
    const value = parseFloat(event.target.value);
    const fieldName = event.target.getAttribute('data-field');
    if (value != item[fieldName]) {
      if (fieldName === 'indQ_U1') {
        item['Ind_u1_bgColor'] = this.unitColor;
      }
    }
    item[fieldName] = isNaN(value) ? 0 : value;
  }

  onUpdate() {
    this.isUpdateLoading = true;
    this.checkDashboardList(false);
  }

  protected openModal(
    modalToOpen: TemplateRef<any>,
    item: any,
    isBlock: boolean
  ) {
    this.orderObj = {
      lookupList: this.lookupList,
      order: item,
    };
    item.isBlock = isBlock;
    this.orderLabel = isBlock ? 'Block2H' : 'HalfHour Block';
    if (this.activeLink === 'saltend') {
      this.buySellLabel = item.saL_TODO > 0 ? 'SELL' : 'BUY';
    } else if (this.activeLink === 'indqueens') {
      this.buySellLabel = item.indQ_TODO > 0 ? 'SELL' : 'BUY';
    } else if (this.activeLink === 'retail') {
      this.buySellLabel = item.retaiL_TODO > 0 ? 'SELL' : 'BUY';
    } else if (this.activeLink === 'hydro') {
      this.buySellLabel = item.hydrO_TODO > 0 ? 'SELL' : 'BUY';
    }
    this.dialogRef = this.dialog.open(modalToOpen);
  }

  openOrderModel(model: TemplateRef<any>, item: any) {
    this.orderRequest = item;
    this.dialogRef = this.dialog.open(model, {});
    const models = document.querySelector('.nj-modal__dialog');
    if (models) {
      models.classList.remove('nj-modal__dialog');
    }
  }

  openMELSELModel(model: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(model);
    this.getMELSEL();
  }

  protected closeModal() {
    this.dialogRef?.close();
  }
}
