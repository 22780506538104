<section id="triton">
  <div class="container-fluid">
    @if (activeLink==='saltend') {
    <!--SALTEND Dashboard Listing -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-4">
            <h3>
              Last updated time ({{
              lastSyncDateTime | date : "dd/MM/yyyy, hh:mm a"
              }})
            </h3>
          </div>
          <div class="col-md-3">

          </div>
          <div class="col-md-5 btn">
            <button type="button" (click)="onUpdate()" [disabled]="isUpdateLoading" class="nj-btn">
              Update ALL
              <span aria-live="polite" aria-atomic="true" [class.nj-spinner]="isUpdateLoading"
                [class.nj-spinner--inverse]="isUpdateLoading"
                [class.nj-spinner--xs]="isUpdateLoading"></span></button>&nbsp;&nbsp;

          </div>
        </div>
      </div>
      <div class="panel-body mainrow">
        <hr />
        <div class="row row-header1">
          <div class="col-md-12">
            <table class="nj-table nj-table--hover">
              <colgroup>
                <col id="hhTH" span="3" />
                <col id="saltTH" span="9" />
              </colgroup>
              <thead>
                <tr>
                  <th id="hhTHBg" colspan="3">HH local Date/Time</th>
                  <th id="unitsTHBg" [attr.colspan]="saltend_unitsColumnCount">PNs (Units)</th>
                  <th id="saltTHBg" [attr.colspan]="saltend_positionsColumnsCount">Positions and orders</th>
                </tr>
                <tr>
                  <th>DATETIME</th>
                  <th>Block</th>
                  <th>PERIOD</th>

                  <th>U1</th>

                  <th>U2</th>

                  <th>U3</th>

                  <th>U4</th>

                  <th>REQUIRED</th>

                  <th>CONTRACTED</th>

                  <th>TODO</th>

                  <th>SUBMITTED</th>

                  <th>PENDING</th>

                </tr>
              </thead>

              <tbody>
                @for (item of dashboardList; track item) {
                <tr [class]="getRowClass(item)">
                  <td scope="row">{{ item.settlementDate }}</td>
                  <td>
                    <span class="todo"
                     >
                      {{ item.block }} </span>
                  </td>
                  <td>{{ item.period }}</td>

                  <td class="list_input" scope="row">
                    {{item.saL_U1}}
                  </td>

                  <td class="list_input">
                   {{item.saL_U2}}
                  </td>

                  <td class="list_input">
                   {{item.saL_U3}}
                  </td>

                  <td class="list_input">
                   {{item.saL_U4}}
                  </td>

                  <td>{{ calculateREQD(item) }}</td>

                  <td>{{ getSalCount(item) }}</td>

                  <td>
                    <span [class]="item.bgColor" >
                      {{ calculateTODO(item) }} </span>
                  </td>

                  <td>
                    <span [class]="item.sal_submitted_badge"
                      class="todo">{{ convertValue(item.saL_Submitted) }}</span>
                  </td>

                  <td>{{ convertValue(item.pendingQty) }}</td>

                </tr>
                } @if (dashboardList?.length == 0) {
                <tr>
                  <td colspan="13" class="msg">No Records Found!</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    } @else if (activeLink==='indqueens') {
    <!--INDIAN QUEEN Dashboard Listing -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-4">
            <h3>
              Last updated time ({{
              lastSyncDateTime | date : "dd/MM/yyyy, hh:mm a"
              }})
            </h3>
          </div>
          <div class="col-md-3">

          </div>
          <div class="col-md-5 btn">
            <button type="button" (click)="onUpdate()" [disabled]="isUpdateLoading" class="nj-btn">
              Update ALL
              <span aria-live="polite" aria-atomic="true" [class.nj-spinner]="isUpdateLoading"
                [class.nj-spinner--inverse]="isUpdateLoading"
                [class.nj-spinner--xs]="isUpdateLoading"></span></button>&nbsp;&nbsp;

          </div>
        </div>
      </div>

      <div class="panel-body mainrow">
        <hr />
        <div class="row row-header1">
          <div class="col-md-12">
            <table class="nj-table nj-table--hover">
              <colgroup>
                <col id="hhTH" span="3" />
                <col id="indTH" span="8" />
              </colgroup>
              <thead>
                <tr>
                  <th id="hhTHBg" colspan="3">HH local Date/Time</th>

                  <th id="unitsTHBg" [attr.colspan]="indQueen_unitsColumnCount">PNs (Units)</th>


                  <th id="saltTHBg" [attr.colspan]="indQueen_positionsColumnsCount">Positions and orders</th>

                </tr>
                <tr>
                  <th>DATETIME</th>
                  <th>Block</th>
                  <th>PERIOD</th>

                  <th>U1</th>

                  <th>CONTRACTED</th>

                  <th>TODO</th>

                  <th>SUBMITTED</th>

                  <th>PENDING</th>

                </tr>
              </thead>
              <tbody>
                @for (item of dashboardList; track item) {
                <tr [class]="getRowClass(item)">
                  <td scope="row">{{ item.settlementDate }}</td>
                  <td>
                    <span  class="todo">
                      {{ item.block }}
                    </span>
                  </td>
                  <td>{{ item.period }}</td>

                  <td class="list_input" scope="row">
                    {{item.indQ_U1}}
                  </td>

                  <td>{{ item.indQ_Cont }}</td>

                  <td>
                    <span [class]="item.bgColor"
                     >{{ calculateINDQUETODO(item) }}</span>
                  </td>

                  <td>
                    <span [class]="item.indQ_submitted_badge"
                      class="todo">{{ convertValue(item.indQ_Submitted) }}</span>
                  </td>

                  <td>{{ convertValue(item.indQ_PendingQty) }}</td>

                </tr>
                } @if (dashboardList?.length == 0) {
                <tr>
                  <td colspan="7" class="msg">No Records Found!</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    } @else if (activeLink==='retail') {
      <!--RETAIL Dashboard Listing -->
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="row">
            <div class="col-md-5">
              <h3>
                Last updated time ({{
                lastSyncDateTime | date : "dd/MM/yyyy, hh:mm a"
                }})
              </h3>
            </div>
            <div class="col-md-4">
              <!-- <form [formGroup]="columnForm">
                <nj-multi-select [isFloatingLabel]="true" formControlName="columns">
                  <ng-container njSelectOptions>
                    <li nj-list-item>TODO</li>
                    <li nj-list-item>SUBMITTED</li>
                    <li nj-list-item>PENDING</li>
                  </ng-container>
                  <ng-container njFormLabel>Hide INDIAN QUEENS Columns</ng-container>
                </nj-multi-select>
              </form> -->
            </div>
            <div class="col-md-3 btn">
              <button type="button" (click)="onUpdate()" [disabled]="isUpdateLoading" class="nj-btn">
                Update ALL
                <span aria-live="polite" aria-atomic="true" [class.nj-spinner]="isUpdateLoading"
                  [class.nj-spinner--inverse]="isUpdateLoading"
                  [class.nj-spinner--xs]="isUpdateLoading"></span></button>&nbsp;&nbsp;

            </div>
          </div>
        </div>

        <div class="panel-body mainrow">
          <hr />
          <div class="row row-header1">
            <div class="col-md-12">
              <table class="nj-table nj-table--hover">
                <colgroup>
                  <col id="hhTH" span="3" />
                  <col id="retailTH" span="8" />
                </colgroup>
                <thead>
                  <tr>
                    <th id="hhTHBg" colspan="3">HH local Date/Time</th>

                    <th id="saltTHBg" [attr.colspan]="indQueen_positionsColumnsCount">Positions and orders</th>
                  </tr>
                  <tr>
                    <th>DATETIME</th>
                    <th>Block</th>
                    <th>PERIOD</th>
                    <th>TODO</th>
                    <th>SUBMITTED</th>
                    <th>PENDING</th>

                  </tr>
                </thead>
                <tbody>
                  @for (item of dashboardList; track item) {
                  <tr [class]="getRowClass(item)">
                    <td scope="row">{{ item.settlementDate }}</td>
                    <td>
                      <span class="todo"
                       >
                        {{ item.block }}
                      </span>
                    </td>
                    <td>{{ item.period }}</td>
                    <td>
                      <span  [class]="item.bgColor"
                      >{{ calculateRETAILTODO(item) }}</span>
                    </td>
                    <td>
                      <span [class]="item.retaiL_submitted_badge"
                        class="todo">{{ convertValue(item.retaiL_Submitted) }}</span>
                    </td>
                    <td>{{ convertValue(item.retaiL_PendingQty) }}</td>

                  </tr>
                  } @if (dashboardList?.length == 0) {
                  <tr>
                    <td colspan="7" class="msg">No Records Found!</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      }



  </div>


</section>
