<section id="triton">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-10"><h3>Confirmations</h3></div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="panel-body mainrow">
        <hr />
        <div class="row">
          @if (activeLink==='saltend') {
          <!-- <div class="col-md-1"></div> -->
          <div class="col-md-3 tablespace">
            <div class="row row-header2">SALTEND</div>
            <div class="row">
              <table id="saltendTable" class="nj-table nj-table--hover">
                <tbody>
                  <tr>
                    <td scope="col">No Units On</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.saltend_no_units_on"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">2 Units On</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.saltend_two_units_on"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">TEC</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.saltend_tec"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">1 Unit On</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.saltend_one_units_on"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">All Units On</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.saltend_all_units_on"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">TLM</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.erg_tlm"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-1"></div>
          } @else if (activeLink==='indqueens') {
          <div class="col-md-3 tablespace">
            <div class="row row-header3">INDIAN QUEENS</div>
            <div class="row">
              <table id="IndianQueensTable" class="nj-table nj-table--hover">
                <tbody>
                  <tr>
                    <td scope="col">Works Power On</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.indquen_works_power_on"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">TEC</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.indquen_tec"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">IQ Transmission Loss</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.indquen_iq_transmission_loss"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="col">IQ Transmission</td>
                    <td scope="col">
                      <div class="nj-form-item--static nj-form-item--sm">
                        <div class="nj-form-item__field-wrapper">
                          <input
                            type="number"
                            disabled
                            [value]="appConfigObj.indquen_iq_transmission"
                            class="nj-form-item__field textBox"
                            id="exampleSmFloatingInputsm"
                            placeholder="default"
                          />
                          <label
                            for="exampleSmFloatingInputsm"
                            class="nj-form-item__label"
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
